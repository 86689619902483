const localizationConstants: { [key: string]: string } = {
	dashboardTitle: "dashboardTitle",
	autoGeneratedLicenseGraphTitle: "autoGeneratedLicenseGraphTitle",
	upcomingCreatedLicenseGraphTitle: "upcomingCreatedLicenseGraphTitle",
	daily: "daily",
	weekly: "weekly",
	monthly: "monthly",
	today: "today",
	yesterday: "yesterday",
	thisWeek: "thisWeek",
	lastWeek: "lastWeek",
	thisMonth: "thisMonth",
	lastMonth: "lastMonth",
	thisYear: "thisYear",
	lastYear: "lastYear",
	custom: "custom",
	backBtnText: "backBtnText",
	organizationsTitle: "organizationsTitle",
	superAdmin: "superAdmin",
	license: "license",
	support: "support",
	licenses: "licenses",
	posbillAdmin: "posbillAdmin",
	incomingLicensesTitle: "incomingLicensesTitle",
	machineListTitle: "machineListTitle",
	excludeDebAutoId: "excludeDebAutoId",
	enterDebAutoIds: "enterDebAutoIds",
	excludeCustomerId: "excludeCustomerId",
	enterCustomerIds: "enterCustomerIds",
	excludeFilterNote: "excludeFilterNote",
	excludedIdsList: "excludedIdsList",
	excludeFilterPlaceholder: "excludeFilterPlaceholder",
	excludeFilterErrorMessage: "excludeFilterErrorMessage",
	addedRemovedTagMessage: "addedRemovedTagMessage",
	undoRemove: "undoRemove",
	remove: "remove",
	excludedId: "excludedId",
	toBeRemoveId: "toBeRemoveId",
	goingToExcludeId: "goingToExcludeId",
	excludeFilter: "excludeFilter",
	autoUpdateMachineTitle: "autoUpdateMachineTitle",
	licenseNumberView: "licenseNumberView",
	orderCodeExtensions: "orderCodeExtensions",
	orderCodeExtensionsList: "orderCodeExtensionsList",
	configurations: "configurations",
	configurationsList: "configurationsList",
	editConfigurationValue: "editConfigurationValue",
	licenseDecoder: "licenseDecoder",
	order_code_id: "order_code_id",
	order_code_used: "order_code_used",
	order_code_used_at: "order_code_used_at",
	users: "users",
	user: "user",
	list: "list",
	create: "create",
	yesCreateAndSendMail: "yesCreateAndSendMail",
	createAndSendMail: "createAndSendMail",
	createAndOpenInSevDesk: "createAndOpenInSevDesk",
	yesCreate: "yesCreate",
	yesReCreate: "yesReCreate",
	rolesAndPermissions: "rolesAndPermissions",
	roles: "roles",
	role: "role",
	status: "status",
	permissions: "permissions",
	addUser: "addUser",
	supportList: "supportList",
	licenseList: "licenseList",
	superAdminList: "superAdminList",
	logout: "logout",
	home: "home",
	view: "view",
	createLicense: "createLicense",
	delete: "delete",
	restore: "restore",
	edit: "edit",
	save: "save",
	all: "all",
	select: "select",
	customer: "customer",
	customers: "customers",
	customerList: "customerList",
	partner: "partner",
	leads: "leads",
	search: "search",
	licenseNumbers: "licenseNumbers",
	licenseInformation: "licenseInformation",
	historyLogOfMachineFor: "historyLogOfMachineFor",
	historyLogOfLicenseFor: "historyLogOfLicenseFor",
	unassigned: "unassigned",
	organizationDetails: "organizationDetails",
	profile: "profile",
	editUser: "editUser",
	notFoundMsg: "notFoundMsg",
	pageNotFound: "pageNotFound",
	actions: "actions",
	orgTimeline: "orgTimeline",
	noDataFoundText: "noDataFoundText",
	serialNumber: "serialNumber",
	name: "name",
	code: "code",
	taxRate: "taxRate",
	taxSet: "taxSet",
	taxRateName: "taxRateName",
	country: "country",
	update: "update",
	email: "email",
	recipientEmail: "recipientEmail",
	senderEmail: "senderEmail",
	password: "password",
	posbill15: "Password V15",
	phoenixPassword: "phoenixPassword",
	oldPassword: "oldPassword",
	newPassword: "newPassword",
	nextPassword: "Next Password",
	getPassword: "Get Password",
	currentPassword: "Current Password",
	confirmPassword: "confirmPassword",
	passwordConfirmation: "passwordConfirmation",
	createdAt: "createdAt",
	updatedAt: "updatedAt",
	difference: "difference",
	functions: "functions",
	hardwareKey: "hardwareKey",
	noOfRecords: "noOfRecords",
	noOfRecordsMinimumValidationMessage: "noOfRecordsMinimumValidationMessage",
	hw_id: "hw_id",
	nextButton: "nextButton",
	licenseNumber: "licenseNumber",
	expiryDateType: "expiryDateType",
	week: "week",
	weeks: "weeks",
	year: "year",
	month: "month",
	months: "months",
	typeValue: "typeValue",
	value: "value",
	expiryDate: "expiryDate",
	autoLicense: "autoLicense",
	version: "version",
	timestampInsert: "timestampInsert",
	timestampUpdate: "timestampUpdate",
	activeLicense: "activeLicense",
	nextLicense: "nextLicense",
	posbillVersion: "posbillVersion",
	address: "address",
	postal_code: "postal_code",
	update_able: "update_able",
	version_2: "version_2",
	sql_server: "sql_server",
	beauty: "beauty",
	boutique: "boutique",
	friseur: "friseur",
	direktverkauf: "direktverkauf",
	baeckerei: "baeckerei",
	kiosk: "kiosk",
	hospitality: "hospitality",
	retail: "retail",
	fun_version: "fun_version",
	change_of_days: "change_of_days",
	pb_p_version: "pb_p_version",
	db_version: "db_version",
	is_locked: "is_locked",
	hashcode: "hashcode",
	auto_license: "auto_license",
	auto_update: "auto_update",
	windows_version: "windows_version",
	mpos: "mpos",
	wawi: "wawi",
	order_code: "order_code",
	configuration_name: "configuration_name",
	configuration_value: "configuration_value",
	timestamp_insert: "timestamp_insert",
	timestamp_update: "timestamp_update",
	partner_id: "partner_id",
	swn_name: "swn_name",
	swn_surname: "swn_surname",
	sepa_name: "sepa_name",
	sepa_surname: "sepa_surname",
	bank_account_name: "bank_account_name",
	bank_name: "bank_name",
	bank_iban: "bank_iban",
	iban: "iban",
	amount: "amount",
	software: "software",
	bank_bic: "bank_bic",
	autoUpdate: "autoUpdate",
	autoUpdateOn: "autoUpdateOn",
	autoUpdateOff: "autoUpdateOff",
	validFrom: "validFrom",
	validTo: "validTo",
	newLicense: "newLicense",
	manageModules: "manageModules",
	viewModules: "viewModules",
	UnableToFindAnActiveLicense: "UnableToFindAnActiveLicense",
	lock: "lock",
	locked: "locked",
	unlock: "unlock",
	unlocked: "unlocked",
	licenseLock: "licenseLock",
	licenseUnlock: "licenseUnlock",
	machineLock: "machineLock",
	machineUnlock: "machineUnlock",
	licenseLocked: "licenseLocked",
	licenseUnlocked: "licenseUnlocked",
	machineLocked: "machineLocked",
	machineUnlocked: "machineUnlocked",
	machineAutoLicenseEnabled: "machineAutoLicenseEnabled",
	machineAutoLicenseDisabled: "machineAutoLicenseDisabled",
	sendLicenseEmail: "sendLicenseEmail",
	generate: "generate",
	copyEmailAndPasswordToClipboard: "copyEmailAndPasswordToClipboard",
	submit: "submit",
	submitWithEmail: "submitWithEmail",
	cancel: "cancel",
	yes: "yes",
	no: "no",
	on: "on",
	off: "off",
	archived: "archived",
	archive: "archive",
	active: "active",
	Active: "Active",
	addNewUser: "addNewUser",
	createTaxRule: "createTaxRule",
	id: "id",
	module: "module",
	close: "close",
	noUsersAvailable: "noUsersAvailable",
	slug: "slug",
	language: "language",
	german: "german",
	english: "english",
	noPermissionsAvailable: "noPermissionsAvailable",
	changePassword: "changePassword",
	updateProfileTitle: "updateProfileTitle",
	changePasswordTitle: "changePasswordTitle",
	editUserDetails: "editUserDetails",
	displayInInvoiceCreation: "displayInInvoiceCreation",
	inventoryGroupStatus: "inventoryGroupStatus",
	youDontHavePermission: "youDontHavePermission",
	newRegisteredUser: "newRegisteredUser",
	inActive: "inActive",
	blocked: "blocked",
	incomingLicenseList: "incomingLicenseList",
	licenseNumbersList: "licenseNumbersList",
	forgetPassword: "forgetPassword",
	getPasswordResetCode: "getPasswordResetCode",
	resetInstructionsAreSentToYourEmail: "resetInstructionsAreSentToYourEmail",
	backToLogin: "backToLogin",
	noMachinesFound: "noMachinesFound",
	resetYourPassword: "resetYourPassword",
	resetPassword: "resetPassword",
	generatePassword: "generatePassword",
	login: "login",
	passwordv16resigo: "Password V16 / Resigo",
	hello: "hello",
	licenseDetailsAreHere: "licenseDetailsAreHere",
	thankYou: "thankYou",
	yourPosbillTeam: "yourPosbillTeam",
	licenseReport: "licenseReport",
	standard: "standard",
	premium: "premium",
	demo: "demo",
	fun: "fun",
	default: "default",
	sql: "sql",
	addItem: "addItem",
	item: "item",
	inventoryGroupName: "inventoryGroupName",
	OK: "OK",
	addLicense: "addLicense",
	addHardware: "addHardware",
	add: "add",
	addWithEmail: "addWithEmail",
	send: "send",
	openEmail: "openEmail",
	addNewRole: "addNewRole",
	editRole: "editRole",
	customerData: "customerData",
	name1: "name1",
	name2: "name2",
	street: "street",
	zipCode: "zipCode",
	city: "city",
	nation: "nation",
	phone: "phone",
	fax: "fax",
	ust: "ust",
	expiryDateValidate: "expiryDateValidate",
	activateAutoLicense: "Activate Auto License",
	to: "to",
	cc: "cc",
	subject: "subject",
	content: "content",
	requiredFieldValidation: "requiredFieldValidation",
	fieldSelectionValidation: "fieldSelectionValidation",
	exactLengthValidation: "exactLengthValidation",
	minLengthStrValidation: "minLengthStrValidation",
	minLengthArrValidation: "minLengthArrValidation",
	maxLengthStrValidation: "maxLengthStrValidation",
	maxLengthArrValidation: "maxLengthArrValidation",
	minValueValidation: "minValueValidation",
	maxValueValidation: "maxValueValidation",
	typeValidation: "typeValidation",
	hardwareKeyValidation: "hardwareKeyValidation",
	passwordValidation: "passwordValidation",
	passwordMatchValidation: "passwordMatchValidation",
	amountValidation: "amountValidation",
	confirmRestoreMsg: "confirmRestoreMsg",
	confirmDeleteMsg: "confirmDeleteMsg",
	confirmLogoutMsg: "confirmLogoutMsg",
	confirmAutoLicense: "confirmAutoLicense",
	confirmChangeLicenseVersion: "confirmChangeLicenseVersion",
	confirmChangeLockStatus: "confirmChangeLockStatus",
	confirmChangeMachineLockStatus: "confirmChangeMachineLockStatus",
	confirmMachineAutoLicense: "confirmMachineAutoLicense",
	noLicensesFound: "noLicensesFound",
	noOrderCodeExtensionFound: "noOrderCodeExtensionFound",
	noApiKeyFound: "noApiKeyFound",
	noTaxRuleFound: "noTaxRuleFound",
	noExpiredLicensesFound: "noExpiredLicensesFound",
	addHardwareNotAllowed: "addHardwareNotAllowed",
	customerIdRequirements: "customerIdRequirements",
	givenCustomerIdIsNotValid: "givenCustomerIdIsNotValid",
	relateMachineToCustomer: "relateMachineToCustomer",
	relateCompanyToCustomer: "relateCompanyToCustomer",
	newCustomer: "newCustomer",
	relate: "relate",
	relateAndJumpToCustomer: "relateAndJumpToCustomer",
	details: "details",
	customerId: "customerId",
	customerIds: "customerIds",
	clear: "clear",
	copied: "copied",
	copyText: "copyText",
	totalRecords: "totalRecords",
	apply: "apply",
	canNotCreateCustomerMsg: "canNotCreateCustomerMsg",
	next: "next",
	previous: "previous",
	perPage: "perPage",
	paid: "paid",
	unpaid: "unpaid",
	manual: "manual",
	invalid: "invalid",
	organizationAlreadyExist: "organizationAlreadyExist",
	internal: "internal",
	global: "global",
	local: "local",

	birdhubAdmin: "birdhubAdmin",
	birdhubAdminTitle: "birdhubAdminTitle",
	credentials: "credentials",
	credentialList: "credentialList",
	addNewCredential: "addNewCredential",
	addCredential: "addCredential",
	updateCredential: "updateCredential",
	credentialInfo: "credentialInfo",
	orderDetail: "orderDetail",
	ordersList: "ordersList",
	passwordsList: "passwordsList",
	orderItems: "orderItems",
	order_id: "order_id",
	customer_title: "customer_title",
	order_date: "order_date",
	shop_code: "shop_code",
	total_discount: "total_discount",
	total_gross: "total_gross",
	total_net: "total_net",
	total_shipping_cost: "total_shipping_cost",
	total_vat: "total_vat",
	item_id: "item_id",
	item_text: "item_text",
	single_price: "single_price",
	total_price: "total_price",
	new: "new",
	dispatched: "dispatched",
	delivered: "delivered",
	refused: "refused",
	cancelled: "cancelled",
	clients: "clients",

	apiId: "apiId",
	apiSecretKey: "apiSecretKey",
	type: "type",
	note: "note",
	notes: "notes",

	phoenixAdmin: "phoenixAdmin",
	phoenixAdminTitle: "phoenixAdminTitle",
	companyList: "companyList",
	companyDetail: "companyDetail",
	companyEdit: "companyEdit",
	companyId: "companyId",
	markAsCreated: "markAsCreated",
	markAsComplete: "markAsComplete",
	transactionId: "transactionId",
	phoenixIncomingLicenseList: "phoenixIncomingLicenseList",
	phoenixIncomingLicenseTitle: "phoenixIncomingLicenseTitle",
	isEmailVerified: "isEmailVerified",
	emailVerifiedAt: "emailVerifiedAt",
	verified: "verified",
	priceInInventoryGroup: "priceInInventoryGroup",
	priceNotInInventoryGroup: "priceNotInInventoryGroup",
	pending: "pending",
	posbill: "posbill",
	phoenix: "phoenix",
	accepted: "accepted",
	rejected: "rejected",
	completed: "completed",
	failed: "failed",
	added: "added",
	notAdded: "notAdded",
	subscriptions: "subscriptions",
	subscriptionId: "subscriptionId",
	additionalUsers: "additionalUsers",
	additionalFeatures: "additionalFeatures",
	bankInfo: "bankInfo",
	universeId: "universeId",
	companyName: "companyName",
	isVerified: "isVerified",
	unlimited: "unlimited",
	setUnlimited: "setUnlimited",
	manageSubscription: "manageSubscription",
	resetMasterPassword: "resetMasterPassword",
	manageExpiryDate: "manageExpiryDate",
	addTaxRule: "addTaxRule",
	editTaxRule: "editTaxRule",
	manageUser: "manageUser",
	companyLocked: "companyLocked",
	companyUnlocked: "companyUnlocked",
	confirmChangeCompanyLockStatus: "confirmChangeCompanyLockStatus",
	confirmChangeJumpbirdCompanyStatus: "confirmChangeJumpbirdCompanyStatus",
	confirmCancelSubscription: "confirmCancelSubscription",
	confirmSetPhoenixLicenseDateUnlimited:
		"confirmSetPhoenixLicenseDateUnlimited",
	confirmChangeActiveLicense: "confirmChangeActiveLicense",
	confirmDeleteLicense: "confirmDeleteLicense",
	confirmGetValidLicense: "confirmGetValidLicense",
	confirmResetPassword: "confirmResetPassword",
	confirmGeneratePassword: "confirmGeneratePassword",
	companySubscriptionEmptyMessage: "companySubscriptionEmptyMessage",
	getValidLicense: "getValidLicense",
	confirmChangeUsedOrderCodeStatus: "confirmChangeUsedOrderCodeStatus",

	customerName: "customerName",
	shopCode: "shopCode",
	totalDiscount: "totalDiscount",
	totalGross: "totalGross",
	totalNet: "totalNet",
	totalShippingCost: "totalShippingCost",
	totalVat: "totalVat",

	modules: "modules",
	moduleList: "moduleList",
	addNewModule: "addNewModule",
	addModule: "addModule",
	updateModule: "updateModule",
	price: "price",

	package: "package",
	packages: "packages",
	packageList: "packageList",
	updatePackage: "updatePackage",
	phoenixId: "phoenixId",
	description: "description",

	apiKeyList: "apiKeyList",
	defaultUserCount: "defaultUserCount",
	currentDefaultUsers: "currentDefaultUsers",
	currentTotalUsers: "currentTotalUsers",
	newDefaultUsers: "newDefaultUsers",
	newTotalUsers: "newTotalUsers",

	jumpbirdIncomingLicenseList: "jumpbirdIncomingLicenseList",
	jumpbirdIncomingLicenseTitle: "jumpbirdIncomingLicenseTitle",
	agb_gdpdr: "agb_gdpdr",
	sepa_accepted_at: "sepa_accepted_at",
	licenseInfo: "licenseInfo",
	packageInfo: "packageInfo",
	companies: "companies",
	company_name: "company_name",
	is_advertising: "is_advertising",
	contact_person_first_name: "contact_person_first_name",
	contact_person_last_name: "contact_person_last_name",
	registration_date: "registration_date",
	updated_at: "updated_at",
	onboard_from: "onboard_from",
	terms_conditions_url: "terms_conditions_url",
	package_code: "package_code",
	start_date: "start_date",
	end_date: "end_date",
	subscription_status: "subscription_status",
	bank_account_owner_name: "bank_account_owner_name",
	tax_id: "tax_id",
	bic: "bic",
	website: "website",
	block_reason: "block_reason",
	paymentMethod: "paymentMethod",
	manageSepa: "manageSepa",
	accept: "accept",
	reject: "reject",
	reason: "reason",
	rejectReasonLabel: "rejectReasonLabel",
	blockReasonLabel: "blockReasonLabel",
	cancelSubscriptionReasonLabel: "cancelSubscriptionReasonLabel",
	companyBlocked: "companyLocked",
	companyUnblocked: "companyUnlocked",
	searchByCompanyNameOrEmail: "searchByCompanyNameOrEmail",
	cancelSubscription: "cancelSubscription",
	nex_billing_date: "nex_billing_date",
	assignPackage: "assignPackage",
	bankAccountOwnerName: "bankAccountOwnerName",
	billingCycles: "billingCycles",

	jumpbirdAdmin: "jumpbirdAdmin",
	jumpbirdAdminTitle: "jumpbirdAdminTitle",

	tse: "tse",
	tseSerialUpload: "tseSerialUpload",
	tseDevice: "tseDevice",
	tseDeviceDetail: "tseDeviceDetail",
	tseDeviceList: "tseDeviceList",
	importCSV: "importCSV",
	deviceFile: "deviceFile",
	file: "file",
	upload: "upload",
	supportedType: "supportedType",
	downloadSampleFile: "downloadSampleFile",
	activate: "activate",
	confirmDeviceActivateMsg: "confirmDeviceActivateMsg",
	date: "date",
	deviceDetail: "deviceDetail",
	activitylogs: "activityLogs",
	adminPin: "adminPin",
	admin_pin: "adminPin",
	adminPuk: "adminPuk",
	admin_puk: "adminPuk",
	timeAdminPin: "timeAdminPin",
	time_admin_pin: "timeAdminPin",
	timeAdminPuk: "timeAdminPuk",
	time_admin_puk: "timeAdminPuk",
	app: "app",
	publicKey: "publicKey",
	public_key: "publicKey",
	secret_key: "secretKey",
	secretKey: "secretKey",
	activatedOn: "activatedOn",
	activated_on: "activatedOn",
	expiredAt: "expiredAt",
	expired_at: "expiredAt",
	fieldName: "fieldName",
	changedValue: "changedValue",
	originalValue: "originalValue",
	uploadCount: "uploadCount",
	tseDashboardTitle: "tseDashboardTitle",
	tseBackupUpload: "tseBackupUpload",
	universeCustomers: "universeCustomers",
	universeCustomer: "universeCustomer",
	universeCustomerList: "universeCustomerList",
	tseCustomers: "tseCustomers",
	tseCustomer: "tseCustomer",
	tseCustomerList: "tseCustomerList",
	tseCustomerDetail: "tseCustomerDetail",
	backupStatistics: "backupStatistics",
	portalAccount: "portalAccount",
	backupCount: "backupCount",
	inProgressUpload: "inProgressUpload",
	successfulUpload: "successfulUpload",
	failedUpload: "failedUpload",
	firstUploadAt: "firstUploadAt",
	lastUploadAt: "lastUploadAt",
	backupList: "backupList",
	debAutoId: "debAutoId",
	suggested: "suggested",
	fileName: "fileName",
	fileSize: "fileSize",
	serial_number: "serial_number",
	checksum: "checksum",
	workingDate: "workingDate",
	expiringDate: "expiringDate",
	progressing: "progressing",
	inProgress: "inProgress",
	uploaded: "uploaded",
	downloaded: "downloaded",
	createDownloadRequest: "createDownloadRequest",
	activeBackupData: "activeBackupData",
	backupInProgressText: "backupInProgressText",
	backupReadyText: "backupReadyText",
	backupFiles: "backupFiles",
	generateDownloadLink: "generateDownloadLink",
	generatedLinkText: "generatedLinkText",
	downloadBackup: "downloadBackup",
	confirmCreateDownloadRequest: "confirmCreateDownloadRequest",
	addNewCustomer: "addNewCustomer",
	sendMailText: "sendMailText",
	sendMailNoteText: "sendMailNoteText",
	createCustomerPortalAccount: "createCustomerPortalAccount",
	inventoryGroupNotExist: "inventoryGroupNotExist",

	tools: "tools",
	offerTool: "offerTool",
	bundleGroup: "bundleGroup",
	createNewBundleGroup: "createNewBundleGroup",
	updateBundleGroup: "updateBundleGroup",
	bundle: "bundle",
	bundles: "bundles",
	createNewBundle: "createNewBundle",
	updateBundle: "updateBundle",
	frontendPositionName: "frontendPositionName",
	frontendId: "frontendId",
	color: "color",
	image: "image",
	red: "red",
	green: "green",
	blue: "blue",
	visibility: "visibility",
	products: "products",
	createNewProduct: "createNewProduct",
	updateProduct: "updateProduct",
	shortName: "shortName",
	offerName: "offerName",
	offerDescription: "offerDescription",
	onlyAvailableInAt: "onlyAvailableInAt",
	onlyAvailableInDe: "onlyAvailableInDe",
	netPrice: "netPrice",
	purchaseOrLeasingFlag: "purchaseOrLeasingFlag",
	purchasePrice: "purchasePrice",
	isSalesPriceEditable: "isSalesPriceEditable",
	variantProductPriceOption: "variantProductPriceOption",
	assignProductsToBundle: "assignProductsToBundle",
	assignBundlesToBundleGroup: "assignBundlesToBundleGroup",
	allBundles: "allBundles",
	bundleListOfBundleGroup: "bundleListOfBundleGroup",
	allProducts: "allProducts",
	productListOfBundle: "productListOfBundle",
	manageBundleGroup: "manageBundleGroup",
	manageBundle: "manageBundle",

	zmList: "zmList",
	pbpw: "PBPW",
	csvUpload: "csvUpload",
	passwordCsvUpload: "passwordCsvUpload",
	selectFile: "selectFile",
	inputCloudPath: "inputCloudPath",
	processing: "processing",
	processed: "processed",
	requestDownload: "requestDownload",
	refresh: "refresh",
	download: "download",
	please_select_csv_file: "please_select_csv_file",
	please_select_valid_csv_file: "please_select_valid_csv_file",

	emailVerification: "emailVerification",
	verifyingEmail: "verifyingEmail",
	successfulEmailVerified: "successfulEmailVerified",
	companyCreation: "companyCreation",
	creatingCompany: "creatingCompany",
	startPhoenixNow: "startPhoenixNow",
	redirectToPhoenix: "redirectToPhoenix",
	companyAlreadyValidatedMessage: "companyAlreadyValidatedMessage",
	companyVerificationSuccessMessage: "companyVerificationSuccessMessage",
	companyVerificationFailMessage: "companyVerificationFailMessage",
	companyVerificationTimeoutMessage: "companyVerificationTimeoutMessage",

	fetchDataText: "fetchDataText",
	confirmChangeAutoUpdateMachineStatus:
		"confirmChangeAutoUpdateMachineStatus",
	filter: "filter",
	selectFilterOptionText: "selectFilterOptionText",
	filterSelectedText: "filterSelectedText",
	noFilterSelected: "noFilterSelected",
	pbpVersion: "pbpVersion",
	dbVersion: "dbVersion",

	enable: "enable",
	disable: "disable",
	enabled: "enabled",
	disabled: "disabled",

	resigoAdmin: "resigoAdmin",
	resigoAdminTitle: "resigoAdminTitle",
	machineList: "machineList",
	basic: "basic",
	profi: "profi",
	profiPlus: "profiPlus",
	privateKey: "privateKey",
	roomCount: "roomCount",

	gep: "gep",
	bez: "bez",
	changes: "changes",
	licneseInformation: "licneseInformation",
	posbillGEP: "posbillGEP",
	posbillBEZ: "posbillBEZ",
	phoenixGEP: "phoenixGEP",
	phoenixBEZ: "phoenixBEZ",
	blockAllLicenses: "blockAllLicenses",
	unblockAllLicenses: "unblockAllLicenses",
	blockAllCompany: "blockAllCompany",
	unblockAllCompany: "unblockAllCompany",
	key: "key",
	oldValue: "oldValue",
	newValue: "newValue",
	same: "same",
	changed: "changed",
	confirmFieldSubmitMsg: "confirmFieldSubmitMsg",
	confirmDeleteTaxRule: "confirmDeleteTaxRule",
	sendEmail: "sendEmail",
	reset: "reset",
	doNothing: "doNothing",

	no_label: "no_label",
	non_customer: "non_customer",
	non_partner: "non_partner",
	vip: "vip",
	non_vip: "non_vip",

	none: "none",
	showMore: "showMore",
	showLess: "showLess",

	provision: "provision",
	modProvision: "modProvision",
	pos: "pos",
	posProvision: "posProvision",
	accounting: "accounting",
	invoices: "invoices",
	turnoverStatistics: "turnoverStatistics",
	turnover: "turnover",
	turnoverCategory: "turnoverCategory",
	turnoverCategoryId: "turnoverCategoryId",
	turnoverNet: "turnoverNet",
	turnoverName: "turnoverName",
	turnoverQuantity: "turnoverQuantity",
	turnoverPriceNet: "turnoverPriceNet",
	inventories: "inventories",
	inventoriesList: "inventoriesList",
	inventoryGroup: "inventoryGroup",
	reaList: "reaList",
	invoice: "invoice",
	invoiceGroupList: "invoiceGroupList",
	inventoryList: "inventoryList",
	inventoryGroupList: "inventoryGroupList",
	addInventoryGroup: "addInventoryGroup",
	removeInvoice: "removeInvoice",
	downloadInvoicePDF: "downloadInvoicePDF",
	taxRules: "taxRules",
	documents: "documents",
	documentList: "documentList",
	sevDeskNotLinked: "sevDeskNotLinked",
	sevDeskNotInitiated: "sevDeskNotInitiated",
	sevDeskTaxRules: "sevDeskTaxRules",
	createNote: "createNote",
	createInvoice: "createInvoice",
	generateInvoicePDF: "generateInvoicePDF",
	createInventoriyGroup: "createInventoriyGroup",
	updateInventoriyGroup: "updateInventoriyGroup",
	invoiceWithoutTax: "invoiceWithoutTax",
	reCreateNote: "reCreateNote",
	creditNote: "creditNote",
	periodOfService: "periodOfService",
	serviceDuration: "serviceDuration",
	bankRetrievalDate: "bankRetrievalDate",
	expand: "expand",
	expandAll: "expandAll",
	collapse: "collapse",
	collapseAll: "collapseAll",
	hits: "hits",
	hitsLabel: "hitsLabel",
	hitsKey: "hitsKey",
	noteAlreadyCreated: "noteAlreadyCreated",
	invoiceAlreadyCreated: "invoiceAlreadyCreated",
	reportList: "reportList",
	generateReport: "generateReport",
	generateRea: "generateRea",
	bulkCreateCreditNote: "bulkCreateCreditNote",
	creatingCreditNotes: "creatingCreditNotes",
	loading: "loading",
	quarter: "quarter",
	reportDetail: "reportDetail",
	exportCSV: "exportCSV",
	exportType: "exportType",
	exportCSVComplete: "exportCSVComplete",
	exportCSVFiltered: "exportCSVFiltered",
	exportXLSXFiltered: "exportXLSXFiltered",
	exportAll: "exportAll",
	exportOnlyActives: "exportOnlyActives",
	manageProvisionReportExport: "manageProvisionReportExport",
	tradingDate: "tradingDate",
	partnerList: "partnerList",
	partnerId: "partnerId",
	reportCustomerId: "reportCustomerId",
	contractBeginDate: "contractBeginDate",
	provisionModuleAmount: "provisionModuleAmount",
	provisionW: "provisionW",
	totalMonths: "totalMonths",
	totalCommission: "totalCommission",
	reportActions: "reportActions",
	sum: "sum",
	orderBy: "orderBy",
	orderByColumn: "orderByColumn",
	partner_deb_auto: "partner_deb_auto",
	partner_org_name: "partner_org_name",
	total_amount: "total_amount",
	currentTotal: "currentTotal",
	netAmount: "netAmount",
	invoiceAmount: "invoiceAmount",
	remaining: "remaining",
	salesTax: "salesTax",
	tax_amount: "tax_amount",
	inventoriesNotAvailable: "inventoriesNotAvailable",
	amountMatchedMessage: "amountMatchedMessage",
	amountNotMatchedMessage: "amountNotMatchedMessage",
	asc: "asc",
	desc: "desc",
	commissionStatus: "commissionStatus",
	commissionCalculationStatus: "commissionCalculationStatus",
	reportStatus: "reportStatus",
	confirmDeletePartnerCommission: "confirmDeletePartnerCommission",
	confirmExportPartnerCSV: "confirmExportPartnerCSV",
	confirmDeletePartnerCreditNote: "confirmDeletePartnerCreditNote",
	confirmDeleteCustomerCommission: "confirmDeleteCustomerCommission",
	confirmRevertCustomerCommission: "confirmRevertCustomerCommission",
	confirmRevertCustomerCommissionStatus: "confirmRevertCustomerCommissionStatus",
	confirmRevertModPercentage: "confirmRevertModPercentage",
	confirmRevertPosPercentage: "confirmRevertPosPercentage",
	confirmCreateNoteMessage: "confirmCreateNoteMessage",
	confirmCreateInvoiceMessage: "confirmCreateInvoiceMessage",
	confirmCreateInvoiceAndSendMailMessage:
		"confirmCreateInvoiceAndSendMailMessage",
	confirmDeleteInvoiceMessage: "confirmDeleteInvoiceMessage",
	confirmArchiveInvoiceGroupMessage: "confirmArchiveInvoiceGroupMessage",
	confirmDeleteInventoryGroupMessage: "confirmDeleteInventoryGroupMessage",
	confirmReCreateNoteMessage: "confirmReCreateNoteMessage",
	addCustomerCommission: "addCustomerCommission",
	searchCustomer: "searchCustomer",
	product: "product",
	quantity: "quantity",
	autoCompleteSearchText: "autoCompleteSearchText",
	noCustomerFound: "noCustomerFound",
	somethingWentWrong: "somethingWentWrong",

	confirmRestoreInvoiceGroupMessage: "confirmRestoreInvoiceGroupMessage",
	confirmArchiveProvisionReportMessage:
		"confirmArchiveProvisionReportMessage",
	confirmRestoreProvisionReportMessage:
		"confirmRestoreProvisionReportMessage",
	licenseModule: "licenseModule",

	licenseModuleList: "licenseModuleList",
	classPosbill: "classPosbill",
	modType: "modType",
	not_allowed: "not_allowed",
	pwd_s_amount_lowercase: "pwd_s_amount_lowercase",
	pwd_p_amount_lowercase: "pwd_p_amount_lowercase",
	pwd_s_amount_uppercase: "pwd_s_amount_uppercase",
	pwd_p_amount_uppercase: "pwd_p_amount_uppercase",
	pwd_s_amount_numbers: "pwd_s_amount_numbers",
	pwd_p_amount_numbers: "pwd_p_amount_numbers",
	pwd_s_amount_special_characters: "pwd_s_amount_special_characters",
	pwd_p_amount_special_characters: "pwd_p_amount_special_characters",
	pwd_s_string_length: "pwd_s_string_length",
	pwd_p_string_length: "pwd_p_string_length",
	regular: "regular",
	countable: "countable",

	HardwareAdded: "HardwareAdded",
	AssignedCustomer: "AssignedCustomer",
	MachineUpdated: "MachineUpdated",
	PaymentStatusUpdated: "PaymentStatusUpdated",
	LicenseGenerated: "LicenseGenerated",
	LicenseAdded: "LicenseAdded",
	LicenseActivated: "LicenseActivated",
	LicenseInactivated: "LicenseInactivated",
	LicenseUpdated: "LicenseUpdated",
	LicenseDeleted: "LicenseDeleted",
	LicenseEmailSent: "LicenseEmailSent",
	FutureLicenseActivated: "FutureLicenseActivated",
	ModuleUpdated: "ModuleUpdated",
	HardwareCloned: "HardwareCloned",
	LicenseCloned: "LicenseCloned",

	noFileSelectedErrorMessage: "noFileSelectedErrorMessage",
	selectValidFileErrorMessage: "selectValidFileErrorMessage",
	january: "january",
	february: "february",
	march: "march",
	april: "april",
	may: "may",
	june: "june",
	july: "july",
	august: "august",
	september: "september",
	october: "october",
	november: "november",
	december: "december",

	dateFrom: "dateFrom",
	dateTo: "dateTo",
	dateLastMonth: "dateLastMonth",
	dateLastQuarter: "dateLastQuarter",
	dateCurrentMonth: "dateCurrentMonth",
	dateCurrentQuarter: "dateCurrentQuarter",

	quarter_end_date: "quarter_end_date",
	quarter_start_date: "quarter_start_date",
	created_by: "created_by",
	created_at: "created_at",
	editCustomerPercentages: "editCustomerPercentages",
	handler_update: "handler_update",
	decodeLicense: "decodeLicense",
	decodeAnotherLicense: "decodeAnotherLicense",

	"permission-module-Organizations": "permission-module-Organizations",
	"permission-module-Widgets": "permission-module-Widgets",
	"permission-module-Roles": "permission-module-Roles",
	"permission-module-Users": "permission-module-Users",
	"permission-module-EmailTemplates": "permission-module-EmailTemplates",
	"permission-module-EmailTemplateVariables":
		"permission-module-EmailTemplateVariables",
	"permission-module-Permissions": "permission-module-Permissions",
	"permission-module-Licenses": "permission-module-Licenses",
	"permission-module-CustomerLicense": "permission-module-CustomerLicense",
	"permission-module-Graphs": "permission-module-Graphs",
	"permission-module-BirdHubCredentials":
		"permission-module-BirdHubCredentials",
	"permission-module-PhoenixModules": "permission-module-PhoenixModules",
	"permission-module-PhoenixCompanies": "permission-module-PhoenixCompanies",
	"permission-module-CustomerPhoenixLicense":
		"permission-module-CustomerPhoenixLicense",
	"permission-module-PhoenixPackages": "permission-module-PhoenixPackages",
	"permission-module-Invoices": "permission-module-Invoices",
	"permission-module-TaxRules": "permission-module-TaxRules",
	"permission-module-SevDesk": "permission-module-SevDesk",
	"permission-module-HistoryLogs": "permission-module-HistoryLogs",
	"permission-module-CustomerLicenses": "permission-module-CustomerLicenses",
	"permission-module-TSE": "permission-module-TSE",
	"permission-module-PartnerProvision": "permission-module-PartnerProvision",
	"permission-module-UniverseCustomer": "permission-module-UniverseCustomer",
	"permission-module-TseAdmin": "permission-module-TseAdmin",
	"permission-module-LicenseAdmin": "permission-module-LicenseAdmin",
	"permission-module-ZMListTool": "permission-module-ZMListTool",
	"permission-module-PhoenixBranches": "permission-module-PhoenixBranches",
	"permission-module-PhoenixApiKeys": "permission-module-PhoenixApiKeys",
	"permission-module-JumpbirdCustomerLicense":
		"permission-module-JumpbirdCustomerLicense",
	"permission-module-JumpbirdCompanies":
		"permission-module-JumpbirdCompanies",
	"permission-module-JumpbirdSubscription":
		"permission-module-JumpbirdSubscription",
	"permission-module-BirdHubMarketPlace":
		"permission-module-BirdHubMarketPlace",
	"permission-module-ResigoCustomerLicense":
		"permission-module-ResigoCustomerLicense",
	"permission-module-resigoAdmin": "permission-module-resigoAdmin",
	"permission-module-PBPW": "permission-module-PBPW",
	"permission-module-CustomerInvoice": "permission-module-CustomerInvoice",
	"permission-module-PosBillBackup": "permission-module-PosBillBackup",

	"permission-module-Documents": "permission-module-Documents",
	"organizations.superAdminIndex": "organizations.superAdminIndex",
	"organizations.licenseIndex": "organizations.licenseIndex",
	"organizations.supportIndex": "organizations.supportIndex",
	"organizations.show": "organizations.show",
	"organizations.timeline": "organizations.timeline",
	"organizations.superAdminShow": "organizations.superAdminShow",
	"organizations.licenseShow": "organizations.licenseShow",
	"organizations.supportShow": "organizations.supportShow",
	"organizations.supportLicenseOrganizationDetail":
		"organizations.supportLicenseOrganizationDetail",
	"support.customerLicense.machineList":
		"support.customerLicense.machineList",
	"support.customerLicense.machineLicenses":
		"support.customerLicense.machineLicenses",
	"organizations.metaData.get": "organizations.metaData.get",
	"organizations.metaData": "organizations.metaData",
	"incomingLicense.incomingRequestList":
		"incomingLicense.incomingRequestList",
	"incomingLicense.incomingRequest.show":
		"incomingLicense.incomingRequest.show",
	"incomingLicense.incomingAssignCustomer":
		"incomingLicense.incomingAssignCustomer",
	"incomingLicense.createNewCustomer": "incomingLicense.createNewCustomer",
	"widget.userStatistics": "widget.userStatistics",
	"roles.index": "roles.index",
	"roles.store": "roles.store",
	"roles.show": "roles.show",
	"roles.update": "roles.update",
	"roles.destroy": "roles.destroy",
	"users.index": "users.index",
	"users.store": "users.store",
	"users.archive": "users.archive",
	"users.show": "users.show",
	"users.update": "users.update",
	"users.destroy": "users.destroy",
	"users.restore": "users.restore",
	"emailTemplate.index": "emailTemplate.index",
	"emailTemplate.store": "emailTemplate.store",
	"emailTemplate.show": "emailTemplate.show",
	"emailTemplate.update": "emailTemplate.update",
	"emailTemplate.destroy": "emailTemplate.destroy",
	"emailTemplateVariable.store": "emailTemplateVariable.store",
	"emailTemplateVariable.destroy": "emailTemplateVariable.destroy",
	"permissions.index": "permissions.index",
	"licenses.incoming": "licenses.incoming",
	"licenses.machine_list": "licenses.machine_list",
	"licenses.machine_customers": "licenses.machine_customers",
	"licenses.license_numbers": "licenses.license_numbers",
	"licenses.autoUpdateMachineList": "licenses.autoUpdateMachineList",
	"licenses.autoUpdateMachine": "licenses.autoUpdateMachine",
	"licenses.configuration.list": "licenses.configuration.list",
	"licenses.configuration.detail": "licenses.configuration.detail",
	"licenses.configuration.update": "licenses.configuration.update",
	"customerLicense.machineIndex": "customerLicense.machineIndex",
	"customerLicense.machineStore": "customerLicense.machineStore",
	"customerLicense.machineShow": "customerLicense.machineShow",
	"customerLicense.machineUpdate": "customerLicense.machineUpdate",
	"customerLicense.machineLicenses": "customerLicense.machineLicenses",
	"customerLicenses.machineActiveLicense":
		"customerLicenses.machineActiveLicense",
	"customerLicense.futureLicenseActivate":
		"customerLicense.futureLicenseActivate",
	"incomingLicense.updateMachineData": "incomingLicense.updateMachineData",
	"customerLicense.moduleList": "customerLicense.moduleList",
	"customerLicense.moduleStore": "customerLicense.moduleStore",
	"customerLicense.moduleUpdate": "customerLicense.moduleUpdate",
	"customerLicense.deleteLicense": "customerLicense.deleteLicense",
	"customerLicense.licenseStore": "customerLicense.licenseStore",
	"customerLicense.licenseUpdate": "customerLicense.licenseUpdate",
	"customerLicense.sendEmail": "customerLicense.sendEmail",

	"order-code-extensions.list": "order-code-extensions.list",
	"order-code-extensions.change-status":
		"order-code-extensions.change-status",

	"licenseModule.store": "licenseModule.store",
	"licenseModule.update": "licenseModule.update",
	"licenseModule.changeStatus": "licenseModule.changeStatus",
	"licenseModule.getLicenseNumberDetail":
		"licenseModule.getLicenseNumberDetail",

	"graphs.autoGeneratedLicenseGraph": "graphs.autoGeneratedLicenseGraph",
	"graphs.upcomingCreatedLicenses": "graphs.upcomingCreatedLicenses",
	"phoenix.incomingLicenseRequestList": "phoenix.incomingLicenseRequestList",
	"phoenix.incomingLicenseRequest.show":
		"phoenix.incomingLicenseRequest.show",
	"phoenix.incomingAssignCustomer": "phoenix.incomingAssignCustomer",
	"phoenix.incomingLicenseRequest.setUnlimitedExpiryDate":
		"phoenix.incomingLicenseRequest.setUnlimitedExpiryDate",
	"phoenix.createNewCustomer": "phoenix.createNewCustomer",
	"phoenix.modules.index": "phoenix.modules.index",
	"phoenix.modules.store": "phoenix.modules.store",
	"phoenix.modules.update": "phoenix.modules.update",
	"phoenix.modules.destroy": "phoenix.modules.destroy",
	"phoenixModules.create-new-module": "phoenixModules.create-new-module",
	"phoenixModules.update-module": "phoenixModules.update-module",
	"phoenixModules.change-module-status":
		"phoenixModules.change-module-status",
	"phoenix.companies.index": "phoenix.companies.index",
	"phoenix.companies.show": "phoenix.companies.show",
	"phoenix.companies.update": "phoenix.companies.update",
	"phoenix.companies.requestPassword": "phoenix.companies.requestPassword",
	"phoenix.subscriptions.index": "phoenix.subscriptions.index",
	"phoenix.subscriptions.show": "phoenix.subscriptions.show",
	"phoenix.companies.requestResetPassword":
		"phoenix.companies.requestResetPassword",
	"phoenix.branches.index": "phoenix.branches.index",

	"bird_hub.credentials.index": "bird_hub.credentials.index",
	"bird_hub.credentials.show": "bird_hub.credentials.show",
	"bird_hub.credentials.store": "bird_hub.credentials.store",
	"bird_hub.credentials.update": "bird_hub.credentials.update",

	"birdhub.orders.list": "birdhub.orders.list",
	"birdhub.orders.detail": "birdhub.orders.detail",
	"birdhub.clients.list": "birdhub.clients.list",

	"phoenix.packages.index": "phoenix.packages.index",
	"phoenix.packages.update": "phoenix.packages.update",
	"phoenix.packages.update-status": "phoenix.packages.update-status",

	"jumpbird.incomingCompanyRequestList":
		"jumpbird.incomingCompanyRequestList",
	"jumpbird.incomingCompanyRequest.show":
		"jumpbird.incomingCompanyRequest.show",
	"jumpbird.incomingCompanyRequest.assignCustomer":
		"jumpbird.incomingCompanyRequest.assignCustomer",
	"jumpbird.incomingCompanyRequest.createNewCustomer":
		"jumpbird.incomingCompanyRequest.createNewCustomer",
	"jumpbirdCustomerLicense.listCompanies":
		"jumpbirdCustomerLicense.listCompanies",
	"jumpbirdCustomerLicense.updateSubscription":
		"jumpbirdCustomerLicense.updateSubscription",
	"jumpbird.companies.list": "jumpbird.companies.list",
	"jumpbird.companies.viewDetail": "jumpbird.companies.viewDetail",
	"jumpbird.companies.updateCompanyData":
		"jumpbird.companies.updateCompanyData",
	"jumpbird.subscriptions.listPackages":
		"jumpbird.subscriptions.listPackages",
	"jumpbirdCustomerLicense.manage-sepa":
		"jumpbirdCustomerLicense.manage-sepa",
	"jumpbirdCustomerLicense.changeBlockStatus":
		"jumpbirdCustomerLicense.changeBlockStatus",
	"jumpbirdCustomerLicense.assign-package":
		"jumpbirdCustomerLicense.assign-package",
	"jumpbirdCustomerLicense.cancel-subscription":
		"jumpbirdCustomerLicense.cancel-subscription",
	"jumpbirdCustomerLicense.setSubscriptionPackageEndDate":
		"jumpbirdCustomerLicense.setSubscriptionPackageEndDate",

	//-----------------------------------------Resigo--------------------------------------------

	//Resigo Machine
	"resigoCustomerLicense.listMachines": "resigoCustomerLicense.listMachines",
	"resigoCustomerLicense.addMachine": "resigoCustomerLicense.addMachine",
	"resigoCustomerLicense.updateMachine":
		"resigoCustomerLicense.updateMachine",

	//Resigo License
	"resigoCustomerLicense.addMachineLicense":
		"resigoCustomerLicense.addMachineLicense",
	"resigoCustomerLicense.listMachineLicenses":
		"resigoCustomerLicense.listMachineLicenses",
	"resigoCustomerLicense.updateLicense":
		"resigoCustomerLicense.updateLicense",
	"resigoCustomerLicense.deleteLicense":
		"resigoCustomerLicense.deleteLicense",
	"resigoCustomerLicense.sendLicenseEmail":
		"resigoCustomerLicense.sendLicenseEmail",

	//Resigo Admin Machine
	"resigoAdmin.listMachines": "resigoAdmin.listMachines",
	"resigoAdmin.updateMachineData": "resigoAdmin.updateMachineData",
	"resigoAdmin.viewMachineDetail": "resigoAdmin.viewMachineDetail",

	//Resigo Admin License
	"resigoAdmin.listLicenses": "resigoAdmin.listLicenses",

	"universeCustomer.customerRegistration":
		"universeCustomer.customerRegistration",
	"universeCustomer.changeCustomerPassword":
		"universeCustomer.changeCustomerPassword",

	"tse.deviceList": "tse.deviceList",
	"tse.createDevice": "tse.createDevice",
	"tse.viewDeviceDetail": "tse.viewDeviceDetail",
	"tse.activityLog": "tse.activityLog",
	"tse.importDevice": "tse.importDevice",
	"tse.activateDevice": "tse.activateDevice",
	"tseAdmin.uploadStatistics": "tseAdmin.uploadStatistics",
	"tseAdmin.customerStatistics": "tseAdmin.customerStatistics",
	"universeCustomer.customers": "universeCustomer.customers",
	"universeCustomer.customerDetail": "universeCustomer.customerDetail",
	"tseAdmin.customers": "tseAdmin.customers",
	"tseAdmin.backupStatistics": "tseAdmin.backupStatistics",
	"tseAdmin.backupHistory": "tseAdmin.backupHistory",
	"tseAdmin.downloadRequest": "tseAdmin.downloadRequest",
	"tseAdmin.latestDownloadRequest": "tseAdmin.latestDownloadRequest",
	"tseAdmin.downloadBackup": "tseAdmin.downloadBackup",
	"tseAdmin.get-tse-customer-detail": "tseAdmin.get-tse-customer-detail",

	"zmList.getRequests": "zmList.getRequests",
	"zmList.addRequest": "zmList.addRequest",
	"zmList.getRequestStatus": "zmList.getRequestStatus",
	"zmList.requestDownloadOutputFile": "zmList.requestDownloadOutputFile",
	"zmList.getUploadFileTempCredential": "zmList.getUploadFileTempCredential",

	pbpwGetCurrentAndNextHourPasswords: "pbpw.getCurrentAndNextHourPasswords",
	pbpwListPosbillPasswords: "pbpw.listPosbillPasswords",
	pbpwStore: "pbpw.storePasswords",
	pbpwGetUploadFileTempCredential: "pbpw.getUploadFileTempCredential",
	pbpwGetCurrentAndNextHourPasswordsOfCustomDate:
		"pbpw.getCurrentAndNextHourPasswordsOfCustomDate",

	"organizations.payment_status.detail":
		"organizations.payment_status.detail",
	"organizations.payment_status.edit": "organizations.payment_status.edit",
	"organizations.payment_status.update":
		"organizations.payment_status.update",
	"organizations.payment_status.sendEmail":
		"organizations.payment_status.sendEmail",
	"partnerProvision.list-all-provision-reports":
		"partnerProvision.list-all-provision-reports",
	"partnerProvision.list-active-provision-reports":
		"partnerProvision.list-active-provision-reports",
	"partnerProvision.list-archived-provision-reports":
		"partnerProvision.list-archived-provision-reports",
	"partnerProvision.archive-provision-report":
		"partnerProvision.archive-provision-report",
	"partnerProvision.restore-provision-report":
		"partnerProvision.restore-provision-report",
	"organizations.getOrganizationDetail":
		"organizations.getOrganizationDetail",

	"partnerProvision.reportList": "partnerProvision.reportList",
	"partnerProvision.reportDetail": "partnerProvision.reportDetail",
	"partnerProvision.createCreditNote": "partnerProvision.createCreditNote",
	"partnerProvision.recreateCreditNote":
		"partnerProvision.recreateCreditNote",
	"partnerProvision.removeCreditNote": "partnerProvision.removeCreditNote",
	"partnerProvision.generateBulkCreditNote":
		"partnerProvision.generateBulkCreditNote",
	"partnerProvision.reportExport": "partnerProvision.reportExport",
	"partnerProvision.reportPartners": "partnerProvision.reportPartners",
	"partnerProvision.generateReport": "partnerProvision.generateReport",
	"partnerProvision.partnerCommissionStatus":
		"partnerProvision.partnerCommissionStatus",
	"partnerProvision.deletePartnerCommission":
		"partnerProvision.deletePartnerCommission",
	"partnerProvision.reportAddCustomer": "partnerProvision.reportAddCustomer",
	"partnerProvision.deleteCustomerCommission": "partnerProvision.deleteCustomerCommission",
	"partnerProvision.updateCustomerCommission": "partnerProvision.updateCustomerCommission",
	"partnerProvision.updateCustomerCommissionPercentage": "partnerProvision.updateCustomerCommissionPercentage",
	"partnerProvision.resetCustomerCommission": "partnerProvision.resetCustomerCommission",
	"partnerProvision.resetCustomerCommissionStatus": "partnerProvision.resetCustomerCommissionStatus",
	"partnerProvision.updatePartnerProvisionPercentage": "partnerProvision.updatePartnerProvisionPercentage",
	"partnerProvision.resetCustomerCommissionPercentage": "partnerProvision.resetCustomerCommissionPercentage",
	"partnerProvision.exportPartnerReport": "partnerProvision.exportPartnerReport",
	"partnerProvision.customerCommissionStatusUpdate": "partnerProvision.customerCommissionStatusUpdate",
	"partnerProvision.updatePartnerCommissionStatus": "partnerProvision.updatePartnerCommissionStatus",

	"customerPhoenixLicense.canSetUnlimitedExpiryDate":
		"customerPhoenixLicense.canSetUnlimitedExpiryDate",
	"customerPhoenixLicense.changeMasterPassword":
		"customerPhoenixLicense.changeMasterPassword",
	"customerPhoenixLicense.companyIndex":
		"customerPhoenixLicense.companyIndex",
	"customerPhoenixLicense.companyUpdate":
		"customerPhoenixLicense.companyUpdate",
	"customerPhoenixLicense.modulesIndex":
		"customerPhoenixLicense.modulesIndex",
	"customerPhoenixLicense.modulesUpdate":
		"customerPhoenixLicense.modulesUpdate",
	"customerPhoenixLicense.subscriptionsIndex":
		"customerPhoenixLicense.subscriptionsIndex",
	"customerPhoenixLicense.subscriptionsShow":
		"customerPhoenixLicense.subscriptionsShow",
	"customerPhoenixLicense.subscriptionsStore":
		"customerPhoenixLicense.subscriptionsStore",
	"invoices.list": "invoices.list",
	"invoices.getUploadFileTempCredential":
		"invoices.getUploadFileTempCredential",
	"invoices.groups.list": "invoices.groups.list",
	"invoices.groups.list-active-invoice-groups":
		"invoices.groups.list-active-invoice-groups",
	"invoices.groups.list-archived-invoice-groups":
		"invoices.groups.list-archived-invoice-groups",
	"invoices.create": "invoices.create",
	"invoices.removeInvoice": "invoices.removeInvoice",
	"invoice-groups.addRequest": "invoice-groups.addRequest",
	"inventory-group.listInventoryGroup": "inventory-group.listInventoryGroup",
	"inventory-group.createInventoryGroup":
		"inventory-group.createInventoryGroup",
	"inventory-group.getInventoryGroup": "inventory-group.getInventoryGroup",
	"inventory-group.updateInventoryGroup":
		"inventory-group.updateInventoryGroup",
	"inventory-group.deleteInventoryGroup":
		"inventory-group.deleteInventoryGroup",
	"inventory-group.getSuggestion": "inventory-group.getSuggestion",
	"invoices.create-and-send-email": "invoices.create-and-send-email",
	"invoices.generate-shopify": "invoices.generate-shopify",
	"invoices.groups.archive": "invoices.groups.archive",
	"invoices.groups.restore": "invoices.groups.restore",
	"invoices.update-status": "invoices.update-status",
	"invoices.change-status": "invoices.change-status",
	"invoices.generatePDF": "invoices.generatePDF",
	"invoices.customers.list": "invoices.customers.list",
	"invoices.customers.documents.list": "invoices.customers.documents.list",
	"invoices.customers.documents.download":
		"invoices.customers.documents.download",
	"inventory-group.changeInventoryGroupStatus":
		"inventory-group.changeInventoryGroupStatus",
	"invoices.create-invoice-and-send-email-mark-complete":
		"invoices.create-invoice-and-send-email-mark-complete",
	"invoices.invoice-number": "invoices.invoice-number",
	"invoices.fetchInvoiceStatus": "invoices.fetchInvoiceStatus",

	"sev-desk.inventories.sync": "sev-desk.inventories.sync",
	"sev-desk.inventories.list": "sev-desk.inventories.list",

	"tax-rules.list-sev-desk-tax-rules": "tax-rules.list-sev-desk-tax-rules",

	"history-log.machine": "history-log.machine",
	"history-log.list-posbill-license-history":
		"history-log.list-posbill-license-history",
	"history-log.list-organization-change-history":
		"history-log.list-organization-change-history",
	"history-log.list-posbill-machine-auto-update-history":
		"history-log.list-posbill-machine-auto-update-history",
	"history-log.user-activities": "history-log.user-activities",
	"history-log.list-resigo-machine-history":
		"history-log.list-resigo-machine-history",
	"history-log.list-resigo-license-history":
		"history-log.list-resigo-license-history",
	"history-log.list-posbill-machine-history":
		"history-log.list-posbill-machine-history",

	"tax-rules.list-tax-rules": "tax-rules.list-tax-rules",
	"tax-rules.countries": "tax-rules.countries",
	"tax-rules.store": "tax-rules.store",
	"tax-rules.delete": "tax-rules.delete",

	"posbill-backup.uploadStatistics": "posbill-backup.uploadStatistics",
	"posbill-backup.customers": "posbill-backup.customers",
	"posbill-backup.customer.uploadStatistics":
		"posbill-backup.customer.uploadStatistics",
	"posbill-backup.customer.backups": "posbill-backup.customer.backups",
	"posbill-backup.customer.backups.requestDownload":
		"posbill-backup.customer.backups.requestDownload",
	"posbill-backup.listDownloadRequests":
		"posbill-backup.listDownloadRequests",
	"posbill-backup.backup-download": "posbill-backup.backup-download",

	"customerInvoices.listInvoices": "customerInvoices.listInvoices",
	"customerInvoices.downloadInvoices": "customerInvoices.downloadInvoices",
	"customerInvoices.sendInvoice": "customerInvoices.sendInvoice",

	"sev-desk.tax-rules.list": "sev-desk.tax-rules.list",
	mo: "mo",
	di: "di",
	mi: "mi",
	do: "do",
	fr: "fr",
	sa: "sa",
	so: "so",
};
export default localizationConstants;
