/* tslint:disable */
const en = {
	"dashboardTitle": "dashboard",
	"autoGeneratedLicenseGraphTitle": "Auto Generated License",
	"upcomingCreatedLicenseGraphTitle": "Total Created License",
	"daily": "daily",
	"weekly": "weekly",
	"monthly": "monthly",
	"today": "Today",
	"yesterday": "Yesterday",
	"thisWeek": "This Week",
	"lastWeek": "Last Week",
	"thisMonth": "This Month",
	"lastMonth": "Last Month",
	"thisYear": "This Year",
	"lastYear": "Last Year",
	"custom": "Custom",
	"backBtnText": "Back",
	"organizationsTitle": "organizations",
	"superAdmin": "super admin",
	"organizationAlreadyExist": "Organization Already Exists",
	"license": "license",
	"support": "support",
	"licenses": "licenses",
	"posbillAdmin": "Posbill Admin",
	"incomingLicensesTitle": "incoming licenses",
	"machineListTitle": "machine list",
	"excludeDebAutoId": "Exclude DEB-Auto Ids",
	"enterDebAutoIds": "Enter DEB-Auto Ids",
	"excludeCustomerId": "Exclude Customer Ids",
	"enterCustomerIds": "Enter Customer Ids",
	"excludedIdsList": "Excluded ID's List",
	"excludeFilterPlaceholder": "XXXXX ⏎",
	"excludeFilterNote": "Press Enter(⏎) key or Space bar to create tag.",
	"excludeFilterErrorMessage": "Entered ID is already excluded, please enter another ID.",
	"addedRemovedTagMessage": "You entered an id which you just marked to be removed, so now that id will not going to remove.",
	"undoRemove": "Undo Remove Operation",
	"remove": "Remove",
	"excludedId": "Excluded ID",
	"toBeRemoveId": "To be removed ID",
	"goingToExcludeId": "Going to exclude ID",
	"excludeFilter": "Exclude Filter",
	"autoUpdateMachineTitle": "Auto Update Machine",
	"licenseNumberView": "license number view",
	"orderCodeExtensions": "Order Code Extensions",
	"orderCodeExtensionsList": "Order Code Extensions List",
	"configurations": "Configurations",
	"configurationsList": "Configurations List",
	"editConfigurationValue": "Edit configuration value",
	"licenseDecoder": "License Decoder",
	"order_code_id": "id",
	"order_code_used": "is used?",
	"order_code_used_at": "used at",
	"users": "users",
	"user": "User",
	"list": "list",
	"create": "create",
	"yesCreateAndSendMail": "YES, CREATE AND SEND EMAIL",
	"createAndSendMail": "create and send email",
	"createAndOpenInSevDesk": "create and open in sevDesk",
	"yesCreate": "YES, CREATE",
	"yesReCreate": "YES, RECREATE",
	"rolesAndPermissions": "roles & permissions",
	"roles": "roles",
	"role": "Role",
	"status": "Status",
	"permissions": "permissions",
	"addUser": "add user",
	"supportList": "support list",
	"licenseList": "license list",
	"superAdminList": "super admin list",
	"logout": "Log out",
	"home": "Home",
	"view": "view",
	"createLicense": "create license",
	"delete": "Delete",
	"restore": "Restore",
	"edit": "Edit",
	"save": "Save",
	"all": "All",
	"select": "Select",
	"customer": "Customer",
	"Customer": "Customer",
	"customers": "Customers",
	"customerList": "Customer List",
	"partner": "Partner",
	"leads": "Leads",
	"search": "search",
	"licenseNumbers": "license numbers",
	"licenseInformation": "License Information",
	"historyLogOfMachineFor": "History Log Of Machine For",
	"historyLogOfLicenseFor": "History Log Of License For",
	"unassigned": "Unassigned",
	"organizationDetails": "organization details",
	"profile": "profile",
	"editUser": "edit user",
	"notFoundMsg": "Sorry, but the page you are looking for has note been found. Try checking the URL for error, then hit the refresh button on your browser or try found something else in our app.",
	"pageNotFound": "Page Not Found",
	"actions": "Actions",
	"orgTimeline": "Organization Timeline",
	"noDataFoundText": "No data found",
	"serialNumber": "No.",
	"name": "Name",
	"customer_org_name": "Name",
	"code": "Code",
	"taxRate": "Tax rate",
	"taxSet": "Tax set",
	"taxRateName": "Tax Rate Name",
	"country": "Country",
	"update": "Update",
	"email": "Email",
	"recipientEmail": "recipient email",
	"senderEmail": "sender email",
	"password": "Password",
	"phoenixPassword": "Please set your Password",
	"phoenixPasswordSuccessfullyReset": "Password successfully reset!",
	"oldPassword": "Old Password",
	"newPassword": "New Password",
	"getPassword": "Get Password",
	'nextPassword': "Next Password",
	'currentPassword': 'Current Password',
	"confirmPassword": "Confirm Password",
	"passwordConfirmation": "Password Confirmation",
	"createdAt": "Created At",
	"updatedAt": "Updated At",
	"functions": "Functions",
	"hardwareKey": "Hardware Key",
	"noOfRecords": "Number Of Machines",
	"noOfRecordsMinimumValidationMessage": "The value must be greater than 0 (Zero).",
	"hw_id": "Hardware Id",
	"licenseNumber": "License Number",
	"expiryDateType": "Expiry Date Type",
	"week": "Week",
	"weeks": "Weeks",
	"year": "Year",
	"month": "Month",
	"months": "Months",
	"typeValue": "Type Value",
	"value": "Value",
	"nextButton": "Next",
	"expiryDate": "Expiry Date",
	"autoLicense": "Auto License",
	"version": "Version",
	"timestampInsert": "Inserted At",
	"timestampUpdate": "Updated At",
	"activeLicense": "Active License",
	"nextLicense": "Next License",
	"posbillVersion": "PosBill Version",
	"address": "Address",
	"postal_code": "Postal Code",
	"update_able": "updateable",
	"version_2": "version 2",
	"sql_server": "sql server",
	"beauty": "Beauty",
	"boutique": "Boutique",
	"friseur": "Friseur",
	"direktverkauf": "Direktverkauf",
	"baeckerei": "Baeckerei",
	"kiosk": "Kiosk",
	"hospitality": "Hospitality",
	"retail": "Retail",
	"fun_version": "fun version",
	"change_of_days": "change of days",
	"pb_p_version": "PB P version",
	"db_version": "DB version",
	"is_locked": "Is Locked",
	"hashcode": "hashcode",
	"auto_license": "auto license",
	"auto_update": "auto update",
	"windows_version": "window version",
	"mpos": "mpos",
	"wawi": "WaWi",
	"order_code": "order code",
	"configuration_name": "Configuration Name",
	"configuration_value": "Configuration Value",
	"timestamp_insert": "Timestamp Insert",
	"timestamp_update": "Timestamp Update",
	"partner_id": "Partner Id",
	"swn_name": "SWN name",
	"swn_surname": "SWN surname",
	"sepa_name": "SEPA name",
	"sepa_surname": "SEPA surname",
	"bank_account_name": "Bank account name",
	"bank_name": "Bank Name",
	"bank_iban": "Bank IBAN",
	"iban": "IBAN",
	"amount": "Amount",
	"software": "Software",
	"bank_bic": "Bank BIC",
	"autoUpdate": "Auto Update",
	"autoUpdateOn": "Turn Auto Update On",
	"autoUpdateOff": "Turn Auto Update Off",
	"validFrom": "Valid From",
	"validTo": "Valid To",
	"newLicense": "New License",
	"manageModules": "Manage Modules",
	"viewModules": "View Modules",
	"UnableToFindAnActiveLicense": 'Unable to find active license',
	"lock": "Lock",
	"locked": "Locked",
	"unlock": "Unlock",
	"unlocked": "Unlocked",
	"licenseLock": "License lock",
	"licenseUnlock": "License unlock",
	"machineLock": "Machine lock",
	"machineUnlock": "Machine unlock",
	"licenseLocked": "License number locked",
	"licenseUnlocked": "License number unlocked",
	"machineLocked": "Machine locked",
	"machineUnlocked": "Machine unlocked",
	"machineAutoLicenseEnabled": "Machine Auto License Enabled",
	"machineAutoLicenseDisabled": "Machine Auto License Disabled",
	"sendLicenseEmail": "Send license email",
	"generate": "Generate",
	"copyEmailAndPasswordToClipboard": "copy email and password to clipboard",
	"submit": "Submit",
	"submitWithEmail": "Submit with email",
	"cancel": "cancel",
	"yes": "YES",
	"no": "NO",
	"on": "On",
	"off": "Off",
	"archived": "archived",
	"archive": "Archive",
	"active": "active",
	"Active": "Active",
	"addNewUser": "Add New User",
	"createTaxRule": "Create New Tax Rule",
	"id": "Id",
	"module": "Module",
	"close": "Close",
	"noUsersAvailable": "no users available",
	"slug": "Slug",
	"language": "Language",
	"german": "German",
	"english": "English",
	"noPermissionsAvailable": "no permissions available",
	"changePassword": "Change Password",
	"displayInInvoiceCreation": "Change the status",
	"inventoryGroupStatus": "Change the status",
	"youDontHavePermission": "You don't have permission",
	"updateProfileTitle": "Update Your Profile",
	"changePasswordTitle": "Change Your Password",
	"editUserDetails": "Edit User Details",
	"newRegisteredUser": "New Register User",
	"inActive": "InActive",
	"blocked": "Blocked",
	"incomingLicenseList": "PosBill Incoming License List",
	"licenseNumbersList": "License Numbers List",
	"forgetPassword": "Forgot Password",
	"getPasswordResetCode": "Send email",
	"resetInstructionsAreSentToYourEmail": "We have sent a message to the email address you provided. There you will find a link to reset the password.",
	"backToLogin": "Back to Login",
	"noMachinesFound": "no machines found",
	"resetYourPassword": "Reset Your Password",
	"resetPassword": "Reset Password",
	"generatePassword": "Generate Password",
	"login": "Login",
	"hello": "Hello",
	"licenseDetailsAreHere": "Your License details for PosBill are here",
	"thankYou": "Thank You",
	"yourPosbillTeam": "Your PosBill Team.",
	"licenseReport": "License Report",
	"standard": "Standard",
	"premium": "Premium",
	"demo": "Demo",
	"fun": "Fun",
	"default": "Default",
	"sql": "SQL",
	"addItem": "Add Item",
	"item": "Item",
	"inventoryGroupName": "Inventory Group Name",
	"OK": "OK",
	"addLicense": "Add License",
	"addHardware": "Add Hardware",
	"add": "Add",
	"addWithEmail": "add with email",
	"send": "send",
	"openEmail": "open email",
	"addNewRole": "Add New Role",
	"editRole": "Edit Role",
	"customerData": "Customer Data",
	"name1": "Name 1",
	"name2": "Name 2",
	"street": "Street",
	"zipCode": "Zip Code",
	"city": "City",
	"nation": "Nation",
	"phone": "Phone",
	"fax": "Fax",
	"ust": "Ust",
	"expiryDateValidate": "Please select expiry date",
	"activateAutoLicense": "Activate Auto License",
	"to": "To",
	"cc": "CC",
	"subject": "Subject",
	"content": "Content",
	"requiredFieldValidation": "{{field}} is required!",
	"fieldSelectionValidation": "please select {{field}}",
	"exactLengthValidation": "{{field}} must be having exact {{length}} characters!",
	"minLengthStrValidation": "{{field}} must be longer than {{length}} characters!",
	"minLengthArrValidation": "{{field}} must contain minimum {{length}} items!",
	"maxLengthStrValidation": "{{field}} must be shorter than {{length}} characters!",
	"maxLengthArrValidation": "{{field}} must contain maximum {{length}} items!",
	"minValueValidation": "{{field}} must be greater than or equal to {{value}}!",
	"maxValueValidation": "{{field}} must be less than or equal to {{value}}!",
	"typeValidation": "{{field}} must be a valid {{type}}!",
	"hardwareKeyValidation": "{{field}} must contain digits only (must not start with 0)",
	"passwordValidation": "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter and one number and one special character and must not contain white space",
	"passwordMatchValidation": "Passwords do not match",
	"amountValidation": "{{field}} must be a valid value!",
	"confirmRestoreMsg": "Are you sure you want to restore {{field}}?",
	"confirmDeleteMsg": "Are you sure you want to remove {{field}}?",
	"confirmLogoutMsg": "Are you sure you want to logout?",
	"confirmAutoLicense": "Are you sure you want to update auto-license state of given license?",
	"confirmChangeLicenseVersion": "Are you sure you want to change license version?",
	"confirmChangeLockStatus": "Are you sure you want to change license lock status?",
	"confirmChangeMachineLockStatus": "Are you sure you want to change machine lock status?",
	"confirmMachineAutoLicense": "Are you sure you want to change machine auto-license status?",
	"noLicensesFound": "no licenses found",
	"noOrderCodeExtensionFound": "no order code extension found",
	"noApiKeyFound": "no api key found",
	"noTaxRuleFound": "no tax rule found",
	"noExpiredLicensesFound": "no expired licenses found",
	"addHardwareNotAllowed": "You can not add hardware for this organization, as the customer ID is not valid",
	"customerIdRequirements": "(Customer ID field must be a number, and must be between 1 and 99999)",
	"givenCustomerIdIsNotValid": "Customer ID - {{customerId}} is not valid",
	"relateMachineToCustomer": "Relate Machine To Customer",
	"relateCompanyToCustomer": "Relate Company To Customer",
	"newCustomer": "New Customer",
	"customerId": "Customer Id",
	"customerIds": "Customer Ids",
	"customer_id": "Customer Id",
	"copied": "Copied",
	"copyText": "Copy Text",
	"difference": "Difference",
	"relate": "Relate",
	"relateAndJumpToCustomer": "Relate and jump to Customer",
	"details": "Details",
	"totalRecords": "Total Records",
	"apply": "Apply",
	"canNotCreateCustomerMsg": "You can not create a new customer.",
	"next": "Next",
	"previous": "Previous",
	"perPage": "Per Page",
	"paid": "Paid",
	"unpaid": "Unpaid",
	"manual": "Manual",
	"invalid": "Invalid",

	"internal": "internal",
	"global": "global",
	"local": "local",

	"birdhubAdmin": "birdhubAdmin",
	"birdhubAdminTitle": "Birdhub Admin",
	"credentials": "Credentials",
	"credentialList": "Credential List",
	"addNewCredential": "Add New Credential",
	"addCredential": "Add Credential",
	"updateCredential": "Update Credential",
	"credentialInfo": "Credential Info",
	"orderDetail": "Order Details",
	"ordersList": "Orders List",
	"passwordsList": "Passwords List",
	"orderItems": "Order Items",
	"order_id": "Order Id",
	"customer_title": "Customer",
	"order_date": "Order Date",
	"shop_code": "Shop Code",
	"total_discount": "Total Discount",
	"total_gross": "Total Gross",
	"total_net": "Total Net",
	"total_shipping_cost": "Total Shipping Cost",
	"total_vat": "Total Vat",
	"item_id": "Id",
	"item_text": "Item",
	"single_price": "Single Price",
	"total_price": "Total Price",
	"new": "New",
	"dispatched": "Dispatched",
	"delivered": "Delivered",
	"refused": "Refused",
	"cancelled": "Cancelled",
	"clients": "Clients",

	"apiId": "Api Id",
	"apiSecretKey": "Api Secret Key",
	"type": "Type",
	"note": "Note",
	"notes": "Notes",

	"phoenixAdmin": "phoenixAdmin",
	"phoenixAdminTitle": "Phoenix Admin",
	"companyList": "Company List",
	"companyDetail": "Company Detail",
	"companyEdit": "Company Edit",
	"companyId": "Company Id",
	"markAsCreated": "Mark As Created",
	"markAsComplete": "Mark As Complete",
	"transactionId": "Transaction Id",
	"phoenixIncomingLicenseList": "Phoenix Incoming License List",
	"phoenixIncomingLicenseTitle": "Phoenix Incoming Licenses",
	"isEmailVerified": "Is Email Verified",
	"emailVerifiedAt": "Email Verified At",
	"verified": "verified",
	"priceInInventoryGroup": "Price in inventory Group",
	"priceNotInInventoryGroup": "Price not in inventory Group",
	"pending": "Pending",
	"posbill": "Posbill",
	"phoenix": "Phoenix",
	"accepted": "Accepted",
	"rejected": "Rejected",
	"completed": "Completed",
	"failed": "Failed",
	"added": "Added",
	"notAdded": "Not Added",
	"subscriptions": "Subscriptions",
	"subscriptionId": "Subscription Id",
	"additionalUsers": "Additional Users",
	"additionalFeatures": "Additional Features",
	"bankInfo": "Bank Info",
	"universeId": "Universe Id",
	"companyName": "Company Name",
	"isVerified": "isVerified",
	"unlimited": "Unlimited",
	"setUnlimited": "Set Unlimited",
	"manageSubscription": "Manage Subscription",
	"resetMasterPassword": "Reset Master Password",
	"manageExpiryDate": "Manage Expiry Date",
	"addTaxRule": "Add Tax Rule",
	"editTaxRule": "Edit Tax Rule",
	"manageUser": "Manage User",
	"companyLocked": "Company Locked",
	"companyUnlocked": "Company Unlocked",
	"confirmChangeCompanyLockStatus": "Are you sure you want to change company lock status?",
	"confirmChangeJumpbirdCompanyStatus": "Are you sure you want to change company status?",
	"confirmCancelSubscription": "Are you sure you want to cancel subscription?",
	"confirmSetPhoenixLicenseDateUnlimited": "Are you sure you want to set license expiry date to unlimited (01-01-1970)?",
	"confirmChangeActiveLicense": "Are you sure you want set this license as active?",
	"confirmDeleteLicense": "Are you sure you want to delete this license?",
	"confirmGetValidLicense": "Are you sure you want to get valid licenses of this hardware?",
	"confirmResetPassword": "Are you sure you want to reset password?",
	"confirmGeneratePassword": "Are you sure you want to generate password?",
	"companySubscriptionEmptyMessage": "There is no Subscription, please select Subscription before selecting the Modules.",
	"getValidLicense": "get valid license",
	"confirmChangeUsedOrderCodeStatus": "Are you sure you want change the status of the order code?",

	"customerName": "Customer Name",
	"shopCode": "Shop Code",
	"totalDiscount": "Total Discount",
	"totalGross": "Total Gross",
	"totalNet": "Total Net",
	"totalShippingCost": "Total Shipping Cost",
	"totalVat": "Total Vat",

	"modules": "Modules",
	"moduleList": "Module List",
	"addNewModule": "Add New Module",
	"addModule": "Add Module",
	"updateModule": "Update Module",
	"price": "Price",

	"package": "Package",
	"packages": "Packages",
	"packageList": "Package List",
	"updatePackage": "Update Package",
	"phoenixId": "Phoenix Id",
	"description": "Description",

	"apiKeyList": "API Key List",
	"defaultUserCount": "Default Users",
	"currentDefaultUsers": "Current Default Users",
	"currentTotalUsers": "Current Total User",
	"newDefaultUsers": "New Default Users",
	"newTotalUsers": "New Total User",

	"jumpbirdIncomingLicenseList": "Jumpbird Incoming License List",
	"jumpbirdIncomingLicenseTitle": "Jumpbird Incoming License",
	"agb_gdpdr": "AGB & Datenschutz",
	"sepa_accepted_at": "Is SEPA Accepted?",
	"licenseInfo": "License Info",
	"packageInfo": "Package Info",
	"companies": "Companies",
	"company_name": "Company Name",
	"is_advertising": "Is Advertising?",
	"contact_person_first_name": "Contact Person First Name",
	"contact_person_last_name": "Contact Person Last Name",
	"registration_date": "Registration Date",
	"updated_at": "Updated At",
	"onboard_from": "OnBoard From",
	"terms_conditions_url": "Terms Conditions URL",
	"package_code": "Package Code",
	"start_date": "Start Date",
	"end_date": "End Date",
	"subscription_status": "Subscription Status",
	"bank_account_owner_name": "Bank Account Owner Name",
	"tax_id": "Tax Id",
	"bic": "BIC",
	"website": "Website",
	"block_reason": "Block Reason",
	"paymentMethod": "Payment Method",
	"manageSepa": "Manage SEPA",
	"accept": "Accept",
	"reject": "Reject",
	"reason": "Reason",
	"rejectReasonLabel": "Write a reason for rejection.",
	"blockReasonLabel": "Write a reason for block the company.",
	"cancelSubscriptionReasonLabel": "Write a reason for cancelling subscription.",
	"companyBlocked": "Company Blocked",
	"companyUnblocked": "Company Unblocked",
	"searchByCompanyNameOrEmail": "Search by company name or e-mail",
	"cancelSubscription": "Cancel Subscription",
	"nex_billing_date": "Next billing date",
	"assignPackage": "Assign Subscription",
	"bankAccountOwnerName": "Bank Account Owner Name",
	"billingCycles": "Billing Cycles",
	"inventoryGroupNotExist": "Inventory group does not exist",

	"jumpbirdAdmin": "jumpbirdAdmin",
	"jumpbirdAdminTitle": "Jumpbird Admin",

	"tse": "TSE",
	"tseSerialUpload": "TSE Serial Upload",
	"tseDevice": "TSE Device",
	"tseDeviceDetail": "TSE Device Detail",
	"tseDeviceList": "TSE Device List",
	"importCSV": "Import CSV",
	"deviceFile": "Device File",
	"file": "File",
	"upload": "Upload",
	"supportedType": "Supported Type",
	"downloadSampleFile": "Download Sample File",
	"activate": "Activate",
	"confirmDeviceActivateMsg": "Are you sure you want to activate machine?",
	"date": "Date",
	"deviceDetail": "Device Detail",
	"activityLogs": "Activity Logs",
	"adminPin": "Admin Pin",
	"admin_pin": "Admin Pin",
	"adminPuk": "Admin Puk",
	"admin_puk": "Admin Puk",
	"timeAdminPin": "Time Admin Pin",
	"time_admin_pin": "Time Admin Pin",
	"timeAdminPuk": "Time Admin Puk",
	"time_admin_puk": "Time Admin Puk",
	"app": "App",
	"publicKey": "Public Key",
	"public_key": "Public Key",
	"secretKey": "Secret Key",
	"secret_key": "Secret Key",
	"activated_on": "Activated On",
	"activatedOn": "Activated On",
	"expiredAt": "Expired At",
	"expired_at": "Expired At",
	"fieldName": "Field Name",
	"changedValue": "Changed Value",
	"originalValue": "Original Value",
	"uploadCount": "Upload Count",
	"tseDashboardTitle": "TSE Dashboard",
	"tseBackupUpload": "TSE Backup Upload",
	"universeCustomers": "Universe Customers",
	"universeCustomer": "Universe Customer",
	"universeCustomerList": "Universe Customer List",
	"tseCustomers": "TSE Customers",
	"tseCustomer": "TSE Customer",
	"tseCustomerList": "TSE Customer List",
	"tseCustomerDetail": "TSE Customer Detail",
	"backupStatistics": "Backup Statistics",
	"portalAccount": "Portal Account",
	"backupCount": "Backup Count",
	"inProgressUpload": "In Progress Upload",
	"successfulUpload": "Successful Upload",
	"failedUpload": "Failed Upload",
	"firstUploadAt": "First Upload Date",
	"lastUploadAt": "Last Upload Date",
	"backupList": "Backup List",
	"debAutoId": "DEB-Auto",
	"suggested": "Suggested",
	"fileName": "File Name",
	"fileSize": "File Size",
	"serial_number": "Serial Number",
	"checksum": "Checksum",
	"workingDate": "Working Date",
	"expiringDate": "Expiring Date",
	"progressing": "Progressing",
	"inProgress": "In Progress",
	"uploaded": "Uploaded",
	"downloaded": "Downloaded",
	"createDownloadRequest": "Create Download Request",
	"activeBackupData": "Active Backup Data",
	"backupInProgressText": "Your backup is in progress ...",
	"backupReadyText": "Your backup is ready",
	"backupFiles": "Backup files",
	"generateDownloadLink": "Generate Download Link",
	"generatedLinkText": "Backup link is generated click on download button",
	"downloadBackup": "Download Backup",
	"confirmCreateDownloadRequest": "If you request for new backup, then old downloaded backup will be removed. Are you sure want to proceed?",
	"addNewCustomer": "Add New Customer",
	"createCustomerPortalAccount": "Create Customer Portal Account",
	"sendMailText": "Send verification email to customer?",
	"sendMailNoteText": "If no, Customer account will auto verified.",

	"tools": "Tools",
	"offerTool": "Offer Tool",
	"bundleGroup": "Bundle Group",
	"createNewBundleGroup": "Create New Bundle Group",
	"updateBundleGroup": "Update Bundle Group",
	"bundle": "Bundle",
	"bundles": "Bundles",
	"createNewBundle": "Create New Bundle",
	"updateBundle": "Update Bundle",
	"frontendPositionName": "Frontend Position Name",
	"frontendId": "Frontend Id",
	"color": "Color",
	"image": "Image",
	"red": "Red",
	"green": "Green",
	"blue": "Blue",
	"visibility": "Visibility",
	"products": "Products",
	"createNewProduct": "Create New Product",
	"updateProduct": "Update Product",
	"shortName": "Short name",
	"offerName": "Offer Name",
	"offerDescription": "Offer Description",
	"onlyAvailableInAt": "Only available in Austria?",
	"onlyAvailableInDe": "Only available in Germany?",
	"netPrice": "Net price",
	"purchaseOrLeasingFlag": "Purchase or leasing flag available?",
	"purchasePrice": "Purchase price",
	"isSalesPriceEditable": "Is sales price editable?",
	"variantProductPriceOption": "Variant product price option available?",
	"assignProductsToBundle": "Assign products to bundle",
	"assignBundlesToBundleGroup": "Assign bundles to bundle group",
	"allBundles": "All bundles",
	"bundleListOfBundleGroup": "Bundle list of bundle group",
	"allProducts": "All products",
	"productListOfBundle": "Product list of bundle",
	"manageBundleGroup": "Manage bundle group",
	"manageBundle": "Manage bundle",

	"zmList": "ZM List",
	"pbpw": "PBPW",
	"csvUpload": "ZMList Input File Upload",
	"passwordCsvUpload": "Upload Password CSV",
	"selectFile": "Select File",
	"inputCloudPath": "Input Cloud Path",
	"processing": "Processing",
	"processed": "Processed",
	"requestDownload": "Request Download",
	"refresh": "Refresh",
	"download": "Download",
	"please_select_csv_file": "Please select CSV file.",
	"please_select_valid_csv_file": "Please select valid CSV file.",

	"emailVerification": "Email verification",
	"verifyingEmail": "Verifying email...",
	"successfulEmailVerified": "Email verified successfully!!",
	"companyCreation": "Company creation",
	"creatingCompany": "Creating company...",
	"startPhoenixNow": "Start Phoenix now",
	"redirectToPhoenix": "Go To Phoenix Portal",
	"companyAlreadyValidatedMessage": "Your company is already set up! Click on the link to log in",
	"companyVerificationSuccessMessage": "Your registration was successfull!",
	"companyVerificationFailMessage": "Sorry. Your company could not be verified!",
	"companyVerificationTimeoutMessage": "The creation of your company takes a little longer unexpectedly, but you can log in at the following link",

	"fetchDataText": "Apply filter to fetch data",
	"confirmChangeAutoUpdateMachineStatus": "Are you sure you want to auto update machine?",
	"filter": "Filter",
	"selectFilterOptionText": "Select filter option",
	"filterSelectedText": "filter selected",
	"noFilterSelected": "No Filter Selected",
	"pbpVersion": "PB P Version",
	"dbVersion": "DB Version",

	"enable": "Enable",
	"disable": "Disable",
	"enabled": "Enabled",
	"disabled": "Disabled",
	"roomCount": "Room Count",

	"resigoAdmin": "resigoAdmin",
	"resigoAdminTitle": "Resigo Admin",
	"machineList": "Machine List",
	"basic": "Basic",
	"profi": "Profi",
	"profiPlus": "Profi Plus",
	"privateKey": "Private Key",

	"gep": "GEP",
	"bez": "BEZ",
	"changes": "Changes",
	"posbillGEP": "PosBill GEP",
	"posbillBEZ": "PosBill BEZ",
	"phoenixGEP": "Phoenix GEP",
	"phoenixBEZ": "Phoenix BEZ",
	"blockAllLicenses": "Block All Licenses",
	"unblockAllLicenses": "Unblock All Licenses",
	"blockAllCompany": "Block All Company",
	"unblockAllCompany": "Unblock All Company",
	"key": "Key",
	"oldValue": "Old value",
	"newValue": "New value",
	"same": "same",
	"changed": "changed",
	"confirmFieldSubmitMsg": "Are you sure you want to submit form field data?",
	"confirmDeleteTaxRule": "Are you sure you want to delete tax rule?",
	"sendEmail": "Send Email",
	"reset": "Reset",
	"doNothing": "Do Nothing",

	"no_label": "No Label",
	"non_customer": "Non Customer",
	"non_partner": "Non Partner",
	"vip": "VIP",
	"non_vip": "Non VIP",

	"none": "None",
	"showMore": "Show More",
	"showLess": "Show Less",

	"provision": "Provision",
	"modProvision": "Mod. Provision",
	"module_provision_percentage": "Mod. Provision (%)",
	"pos": "POS",
	"pos_amount": "POS (€)",
	"posProvision": "POS Provision",
	"pos_provision_percentage": "POS Provision (%)",
	"accounting": "Accounting",
	"invoices": "Invoices",
	// "allInvoiceGroups": "All Invoice Group List",
	// "activeInvoiceGroups": "Active Invoice Group List",
	"archivedInvoiceGroups": "Archived Invoice Group List",
	"invoice": "Invoice",
	"reaList": "REA List",
	"turnoverStatistics": "Turnover Statistics",
	"turnover": "Turnover",
	"turnoverCategory": "Category",
	"turnoverCategoryId": "Category Id",
	"turnoverNet": "Turnover (net)",
	"turnoverName": "Product name",
	"turnoverQuantity": "Quantity",
	"turnoverPriceNet": "Price (net)",
	"inventories": "Inventories",
	"inventoryGroup": "Inventory Group",
	"invoiceGroupList": "Invoice Group List",
	"inventoryGroupList": "Inventory Group List",
	"inventoriesList": "Inventories List",
	"addInventoryGroup": "Add Inventory Group",
	"removeInvoice": "Remove invoice",
	"downloadInvoicePDF": "Download Invoice PDF",
	"taxRules": "Tax Rules",
	"documents": "Documents",
	"documentList": "Document List",
	"sevDeskNotLinked": "SevDesk is not linked with this partner yet.",
	"sevDeskNotInitiated": "SevDesk is not initiated yet.",
	"sevDeskTaxRules": "SevDesk Tax Rules",
	"createNote": "Create Note",
	"createInvoice": "Create Invoice",
	"generateInvoicePDF": "Generate Invoice PDF",
	"createInventoriyGroup": "Create Inventory Group",
	"updateInventoriyGroup": "Update Inventory Group",
	"invoiceWithoutTax": "Invoice Without Tax",
	"reCreateNote": "Re-create Note",
	"creditNote": "Credit Note",
	"periodOfService": "Period Of Service",
	"serviceDuration": "Service Duration",
	"bankRetrievalDate": "Bank Retrieval Date",
	"expand": "Expand",
	"expandAll": "Expand All",
	"collapse": "Collapse",
	"collapseAll": "Collapse All",
	"hits": "hits",
	"hitsLabel": "label hits",
	"hitsKey": "key hits",
	"noteAlreadyCreated": "Note Already Created",
	"invoiceAlreadyCreated": "Invoice Already Created",
	"reportList": "Report List",
	"generateReport": "Generate Report",
	"generateRea": "Generate REA List",
	"bulkCreateCreditNote": "Bulk Create Credit Note",
	"creatingCreditNotes": "Creating Credit Notes",
	"loading": "Loading",
	"quarter": "Quarter",
	"reportDetail": "Report Detail",
	"exportCSV": "Export CSV",
	"exportType": "Type",
	"exportCSVComplete": "CSV (complete)",
	"exportCSVFiltered": "CSV (filtered)",
	"exportXLSXFiltered": "XLSX (filtered)",
	"exportAll": "All",
	"exportOnlyActives": "Only actives",
	"manageProvisionReportExport": "Manage Provision Report Export",
	"tradingDate": "Trading Date",
	"partnerList": "Partner List",
	"partnerId": "Partner ID",
	"reportCustomerId": "Customer ID",
	"contractBeginDate": "Contract Begin Date",
	"contract_begin_date": "Contract Begin Date",
	"provisionModuleAmount": "Modules",
	"module_amount": "Modules (€)",
	"provisionW": "Provision W",
	"provision_w_amount": "Provision W",
	"provision_w_pd": "Provision W PD",
	"totalMonths": "Total Months",
	"total_months": "Total Months",
	"totalCommission": "Total Commission",
	"total_commission": "Total Commission (€)",
	"reportActions": "Action",
	"sum": "Sum",
	"orderBy": "Order By",
	"orderByColumn": "Order By Column",
	"partner_deb_auto": "Partner Id",
	"partner_org_name": "Partner Org Name",
	"total_amount": "Total Amount",
	"currentTotal": "Current Total",
	"netAmount": "Net Amount",
	"invoiceAmount": "Invoice Amount",
	"remaining": "Remaining",
	"salesTax": "Sales Tax",
	"tax_amount": "Tax Amount",
	"inventoriesNotAvailable": "Inventories are not available.",
	"amountMatchedMessage": "Total amount matched with expected amount",
	"amountNotMatchedMessage": "Total amount not matching with expected amount",
	"asc": "Ascending",
	"desc": "Descending",
	"commissionStatus": "Commission Status",
	"commissionCalculationStatus": "Commission Calculation Status",
	"reportStatus": "Report Status",
	"confirmDeletePartnerCommission": "Are you sure you want to remove partner commission?",
	"confirmExportPartnerCSV": "Are you sure you want to export partner report?",
	"confirmDeletePartnerCreditNote": "Are you sure you want to remove the partner credit note?",
	"confirmDeleteCustomerCommission": "Are you sure you want to remove customer commission?",
	"confirmRevertCustomerCommission": "Are you sure you want to reset customer commission?",
	"confirmRevertCustomerCommissionStatus": "Are you sure you want to reset customer commission status?",
	"confirmRevertModPercentage": "Are you sure you want to reset customer's MOD percentage?",
	"confirmRevertPosPercentage": "Are you sure you want to reset customer's POS percentage?",
	"confirmCreateNoteMessage": "Are you sure you want to create note?",
	"confirmCreateInvoiceMessage": "Are you sure you want to create invoice?",
	'confirmCreateInvoiceAndSendMailMessage': 'Are you sure you want to create an invoice & send via e-mail?',
	"confirmDeleteInvoiceMessage": "Are you sure you want to remove invoice?",
	"confirmArchiveInvoiceGroupMessage": "Are you sure you want to archive this invoice group?",
	"confirmRestoreInvoiceGroupMessage": "Are you sure you want to restore this invoice group?",
	"confirmArchiveProvisionReportMessage": "Are you sure you want to archive this provision report?",
	"confirmRestoreProvisionReportMessage": "Are you sure you want to restore this provision report?",
	"confirmDeleteInventoryGroupMessage": "Are you sure you want to remove inventory group?",
	"confirmReCreateNoteMessage": "Are you sure you want to re-create note?",
	"addCustomerCommission": "Add Customer Commission",
	"searchCustomer": "Search Customer",
	"product": "Product",
	"quantity": "Quantity",
	"autoCompleteSearchText": "Type atleast 3 character to search...",
	"noCustomerFound": "No Customer Found",
	"somethingWentWrong": "Something went wrong. Please try again!!",

	"not_allowed": " not allowed",
	"pwd_s_amount_lowercase": "at least 1 lowercase letter",
	"pwd_p_amount_lowercase": "at least {{count}} lowercase letters",
	"pwd_s_amount_uppercase": "at least 1 uppercase letter",
	"pwd_p_amount_uppercase": "at least {{count}} uppercase letters",
	"pwd_s_amount_numbers": "at least 1 number",
	"pwd_p_amount_numbers": "at least {{count}} numbers",
	"pwd_s_amount_special_characters": "at least 1 special character",
	"pwd_p_amount_special_characters": "at least {{count}} special characters",
	"pwd_s_string_length": "1 or more characters",
	"pwd_p_string_length": "{{count}} or more characters",

	"licenseModule": "License Module",
	"licenseModuleList": "License Module List",
	"classPosbill": "Class PosBill",
	"modType": "Mod Type",
	"regular": "Regular",
	"countable": "Countable",

	"noFileSelectedErrorMessage": "Please select at least one file",
	"selectValidFileErrorMessage": "Please select valid csv files.",

	"HardwareAdded": "added new hardware",
	"AssignedCustomer": "has assigned a machine to a customer",
	"MachineUpdated": "has updated a machine",
	"PaymentStatusUpdated": "has updated a payment status",
	"LicenseGenerated": "License generated",
	"LicenseActivated": "License activated",
	"LicenseInactivated": "License inactivated",
	"LicenseAdded": "has added a license",
	"LicenseUpdated": "has updated a license",
	"LicenseDeleted": "has deleted a license",
	"LicenseEmailSent": "has sent license details via email",
	"FutureLicenseActivated": "has activated a future license",
	"ModuleUpdated": "has updated a module",
	"HardwareCloned": "Hardware has been cloned",
	"LicenseCloned": "License has been cloned",

	"january": "January",
	"february": "February",
	"march": "March",
	"april": "April",
	"may": "May",
	"june": "June",
	"july": "July",
	"august": "August",
	"september": "September",
	"october": "October",
	"november": "November",
	"december": "December",

	"dateFrom": "From",
	"dateTo": "To",
	"dateLastMonth": "Last month",
	"dateLastQuarter": "Last quarter",
	"dateCurrentMonth": "Current month",
	"dateCurrentQuarter": "Current quarter",

	"quarter_end_date": "Quarter end date",
	"quarter_start_date": "Quarter start date",
	"created_by": "Created by",
	"created_at": "Created at",
	"editCustomerPercentages": "Edit customer's POS Percentage or MOD Percentage",
	"decodeLicense": "Decode License",
	"decodeAnotherLicense": "Decode Another License",

	"permission-module-Organizations": "Organizations",
	"permission-module-Widgets": "Widgets",
	"permission-module-Roles": "Roles",
	"permission-module-Users": "Users",
	"permission-module-EmailTemplates": "Email Templates",
	"permission-module-EmailTemplateVariables": "Email Template Variables",
	"permission-module-Permissions": "Permissions",
	"permission-module-Licenses": "Licenses",
	"permission-module-CustomerLicense": "Customer License",
	"permission-module-Graphs": "Graphs",
	"permission-module-BirdHubCredentials": "Birdhub Credentials",
	"permission-module-PhoenixModules": "Phoenix Modules",
	"permission-module-PhoenixCompanies": "Phoenix Companies",
	"permission-module-CustomerPhoenixLicense": "Phoenix Customer Licenses",
	"permission-module-PhoenixPackages": "Phoenix Packages",
	"permission-module-Invoices": "Invoices",
	"permission-module-TaxRules": "Tax Rules",
	"permission-module-SevDesk": "SevDesk",
	"permission-module-HistoryLogs": "History Logs",
	"permission-module-CustomerLicenses": "Customer Licenses",
	"permission-module-TSE": "TSE",
	"permission-module-PartnerProvision": "Partner Provision",
	"permission-module-UniverseCustomer": "Universe Customer",
	"permission-module-TseAdmin": "TSE Admin",
	"permission-module-LicenseAdmin": "License Admin",
	"permission-module-ZMListTool": "ZM List Tool",
	"permission-module-PhoenixBranches": "Phoenix Branches",
	"permission-module-PhoenixApiKeys": "Phoenix Api Keys",
	"permission-module-JumpbirdCustomerLicense": "Jumpbird Customer License",
	"permission-module-JumpbirdCompanies": "Jumpbird Companies",
	"permission-module-JumpbirdSubscription": "Jumpbird Subscription",
	"permission-module-BirdHubMarketPlace": "BirdHub Marketplace",
	"permission-module-ResigoCustomerLicense": "Resigo Customer License",
	"permission-module-resigoAdmin": "Resigo Admin",
	"permission-module-PBPW": "PBPW",
	"permission-module-CustomerInvoice": "Customer Invoice",
	"permission-module-PosBillBackup": "PosBill Backup",

	'permission-module-Documents': 'Documents',
	"organizations.superAdminIndex": "Super Admin List",
	"organizations.licenseIndex": "License List",
	"organizations.supportIndex": "Support List",
	"organizations.show": "View Organization",
	"organizations.timeline": "View Organization's Timeline",
	"organizations.superAdminShow": "Super Admin Detail View",
	"organizations.licenseShow": "License Detail View",
	"organizations.supportShow": "Support Detail View",
	"organizations.supportLicenseOrganizationDetail": "Support License Organization Details",
	"support.customerLicense.machineList": "Get Customer License Machine List",
	"support.customerLicense.machineLicenses": "Get Customer License Machine Licenses",
	"organizations.metaData": "Organization's Meta Data",
	"organizations.metaData.get": "Get Organization's Meta Data",
	"incomingLicense.incomingRequestList": "Incoming License Requests",
	"incomingLicense.incomingRequest.show": "View Incoming License Request",
	"incomingLicense.incomingAssignCustomer": "Assign Existing Customer to Machine",
	"incomingLicense.createNewCustomer": "Create and Assign new Customer to Machine",
	"widget.userStatistics": "Statistics Widgets",
	"roles.index": "View All Roles",
	"roles.store": "Create Role",
	"roles.show": "View Role",
	"roles.update": "Update Role",
	"roles.destroy": "Delete Role",
	"users.index": "View All Users",
	"users.store": "Create User",
	"users.archive": "View Archive Users List",
	"users.show": "View User Details",
	"users.update": "Update User",
	"users.destroy": "Delete User",
	"users.restore": "Restore User",
	"emailTemplate.index": "View All Email Templates",
	"emailTemplate.store": "Create Email Template",
	"emailTemplate.show": "View Email Template",
	"emailTemplate.update": "Update Email Template",
	"emailTemplate.destroy": "Delete Email Template",
	"emailTemplateVariable.store": "Create Email Template Variable",
	"emailTemplateVariable.destroy": "Delete Email Template Variable",
	"permissions.index": "View All Permissions",
	"licenses.incoming": "Incoming Licenses",
	"licenses.machine_list": "Machine List",
	"licenses.machine_customers": "licenses.machine_customers",
	"licenses.autoUpdateMachineList": "Auto Update Machine List",
	"licenses.autoUpdateMachine": "Auto Update Machine",
	"licenses.configuration.list": "Configuration List",
	"licenses.configuration.detail": "Get Details Of Configuration",
	"licenses.configuration.update": "Update Configuration",
	"licenses.license_numbers": "License Numbers",
	"customerLicense.machineIndex": "Customer Machines",
	"customerLicense.machineStore": "Create Customer Machine",
	"customerLicense.machineShow": "View Customer Machine",
	"customerLicense.machineUpdate": "Update Customer Machine",
	"customerLicense.machineLicenses": "Machine Licenses",
	"customerLicenses.machineActiveLicense": "Machine Active License",
	"customerLicense.futureLicenseActivate": "Activate Future License",
	"incomingLicense.updateMachineData": "Update Machine Data",
	"customerLicense.moduleList": "Manage Modules",
	"customerLicense.moduleStore": "Module Save",
	"customerLicense.moduleUpdate": "Module Update",
	"customerLicense.deleteLicense": "Delete License",
	"customerLicense.licenseStore": "Create Customer license",
	"customerLicense.licenseUpdate": "Customer License Update",
	"customerLicense.sendEmail": "Send License Email",

	"order-code-extensions.list": "Order Code Extensions List",
	"order-code-extensions.change-status": "Change Status Of Order-Code Extension",

	"licenseModule.store": "Create License Module",
	"licenseModule.update": "Update License Module",
	"licenseModule.changeStatus": "Change License Module Status",
	"licenseModule.getLicenseNumberDetail": "Get License Number Detail",

	"graphs.autoGeneratedLicenseGraph": "Show Auto Generated License Graph",
	"graphs.upcomingCreatedLicenses": "Show Upcoming Created Licenses Graph",

	"bird_hub.credentials.index": "View All Birdhub Credentials",
	"bird_hub.credentials.show": "View Birdhub Credential",
	"bird_hub.credentials.store": "Create Birdhub Credential",
	"bird_hub.credentials.update": "Update Birdhub Credential",

	"phoenix.incomingLicenseRequestList": "Phoenix Incoming License Requests",
	"phoenix.incomingLicenseRequest.show": "View Phoenix Incoming License Request",
	"phoenix.incomingAssignCustomer": "Phoenix Assign Existing Customer to Machine",
	"phoenix.incomingLicenseRequest.setUnlimitedExpiryDate": "Phoenix Incoming License Set Unlimited Expiry Date",
	"phoenix.createNewCustomer": "Phoenix Create and Assign new Customer to Machine",
	"phoenix.modules.index": "View All Phoenix Modules",
	"phoenix.modules.store": "Create Phoenix Module",
	"phoenix.modules.update": "Update Phoenix Module",
	"phoenix.modules.destroy": "Delete Phoenix Module",
	"phoenixModules.create-new-module": "Create New The Phoenix Module",
	"phoenixModules.update-module": "Update Phoenix Module",
	"phoenixModules.change-module-status": "Change The Status Of The Phoenix Module",
	"phoenix.companies.index": "View All Phoenix Companies",
	"phoenix.companies.show": "View Phoenix Company",
	"phoenix.companies.update": "Update Phoenix Company Data",
	"phoenix.companies.requestPassword": "Request For Reset Or Generate Password",
	"phoenix.subscriptions.index": "View All Phoenix Company Subscriptions",
	"phoenix.subscriptions.show": "View Phoenix Company Subscription",
	"phoenix.companies.requestResetPassword": "Send Request For Reset Password Of Phoenix Company",
	"phoenix.branches.index": "List Branches Of Phoenix",

	"birdhub.orders.list": "List Of BirdHub Orders",
	"birdhub.orders.detail": "Birdhub Order Details",
	"birdhub.clients.list": "List Of BirdHub Clients",

	"phoenix.packages.index": "View All Phoenix Packages",
	"phoenix.packages.update": "Update Phoenix Packages",
	"phoenix.packages.update-status": "Update Status Of Phoenix Packages",

	"phoenix.api-key.list": "View All Phoenix API Keys",
	"phoenix.api-key.generate": "Generate New Phoenix API Key",

	"jumpbird.incomingCompanyRequestList": "Jumpbird Incoming License Requests",
	"jumpbird.incomingCompanyRequest.show": "View Jumpbird Incoming License Request",
	"jumpbird.incomingCompanyRequest.assignCustomer": "Jumpbird Assign Existing Customer to Machine",
	"jumpbird.incomingCompanyRequest.createNewCustomer": "Jumpbird Create and Assign new Customer to Machine",
	"jumpbirdCustomerLicense.listCompanies": "List All Companies Of A Customer",
	"jumpbirdCustomerLicense.updateSubscription": "Update Jumpbird Incoming License Subscription",
	"jumpbird.companies.list": "View All Jumpbird Companies",
	"jumpbird.companies.viewDetail": "View Jumpbird Company",
	"jumpbird.companies.updateCompanyData": "Update Jumpbird Company Data",
	"jumpbird.subscriptions.listPackages": "View All Jumpbird Company Subscriptions",
	"jumpbirdCustomerLicense.manage-sepa": "Manage Jumpbird SEPA Subscription For Jumpbird Company",
	"jumpbirdCustomerLicense.changeBlockStatus": "Change Jumpbird Company Status (Block | Unblock)",
	"jumpbirdCustomerLicense.assign-package": "Assign SEPA Package To Customer Having FREE | DEMO Subscription.",
	"jumpbirdCustomerLicense.cancel-subscription": "Cancel Jumpbird Company's Subscription",
	"jumpbirdCustomerLicense.setSubscriptionPackageEndDate": "Set Expiry Date Of Jumpbird Company's Subscription",

	//-----------------------------------------Resigo--------------------------------------------

	//Resigo Machine
	"resigoCustomerLicense.listMachines": "Resigo Customer Machine List",
	"resigoCustomerLicense.addMachine": "Add New Machine To Resigo Customer",
	"resigoCustomerLicense.updateMachine": "Update Resigo Customer Machine",

	//Resigo License
	"resigoCustomerLicense.addMachineLicense": "Add New License To Resigo Machine",
	"resigoCustomerLicense.listMachineLicenses": "Resigo Customer Machine's License List",
	"resigoCustomerLicense.updateLicense": "Update Resigo Machine's License",
	"resigoCustomerLicense.deleteLicense": "Delete Resigo Machine's License",
	"resigoCustomerLicense.sendLicenseEmail": "Send Email Of Resigo Machine's License",

	//Resigo Admin Machine
	"resigoAdmin.listMachines": "View Resigo Admin Machine List",
	"resigoAdmin.updateMachineData": "Update Resigo Admin Machine Detail",
	"resigoAdmin.viewMachineDetail": "View Resigo Admin Machine Detail",

	//Resigo Admin License
	'resigoAdmin.listLicenses': "View Resigo Admin Machine's License List",

	"tse.deviceList": "View All TSE Devices",
	"tse.createDevice": "Create TSE Device",
	"tse.viewDeviceDetail": "View TSE Device Detail",
	"tse.activityLog": "TSE Device Activity Log",
	"tse.importDevice": "Import TSE Devices",
	"tse.activateDevice": "Activate TSE Device",
	"tseAdmin.uploadStatistics": "View TSE Upload Statistics Graph",
	"tseAdmin.customerStatistics": "View TSE Customer Statistics Graph",
	"universeCustomer.customers": "View All Universe Customers",
	"universeCustomer.customerDetail": "View TSE Customer Detail",
	"tseAdmin.customers": "View All TSE Customers",
	"tseAdmin.backupStatistics": "View TSE Customer Backup Statistics",
	"tseAdmin.backupHistory": "View TSE Backup History List",
	"tseAdmin.downloadRequest": "Create TSE Backup Download Request",
	"tseAdmin.latestDownloadRequest": "Get Latest Backup Data",
	"tseAdmin.get-tse-customer-detail": "Get TSE Customer Details",
	"tseAdmin.downloadBackup": "Download TSE Backup",
	"universeCustomer.customerRegistration": "Add New TSE Customer",
	"universeCustomer.changeCustomerPassword": "Change The Password For Universe Customer",

	"zmList.getRequests": "View All ZM Files",
	"zmList.addRequest": "Request To Upload ZM File",
	"zmList.getRequestStatus": "Get Status Of ZM File",
	"zmList.requestDownloadOutputFile": "Request for Download Output ZM File",
	"zmList.getUploadFileTempCredential": "Get Temporary Credintial To Upload ZM File",

	"pbpw.getCurrentAndNextHourPasswords": "Get Current And Next Password",
	"pbpw.listPosbillPasswords": "View Password List",
	"pbpw.storePasswords": "Request for Update List",
	"pbpw.getUploadFileTempCredential": "Get Credential For Upload File",
	"pbpw.getCurrentAndNextHourPasswordsOfCustomDate": "Get Current And Next Password by Custom Date",

	"organizations.payment_status.detail": "View Organization Payment Status Detail",
	"organizations.payment_status.edit": "Generate Organization Payment Status Dynamic form data",
	"organizations.payment_status.update": "Update Organization Payment Status Dynamic form data",
	"organizations.payment_status.sendEmail": "Organization Payment Status Send Email",
	"organizations.getOrganizationDetail": "Get The Organization Details",

	"partnerProvision.list-all-provision-reports": "View All Partner Report List",
	"partnerProvision.list-active-provision-reports": "View Active Partner Report List",
	"partnerProvision.list-archived-provision-reports": "View Archived Partner Report List",
	"partnerProvision.archive-provision-report": "Archive Partner Report",
	"partnerProvision.restore-provision-report": "Restore Partner Report",
	"partnerProvision.reportDetail": "View Partner Report Detail",
	"partnerProvision.createCreditNote": "Create Credit Note Of Provision Partner",
	"partnerProvision.recreateCreditNote": "Re-create Credit Note Of Provision Partner",
	"partnerProvision.removeCreditNote": "Remove Credit Note Of Provision Partner",
	"partnerProvision.generateBulkCreditNote": "Generate Credit Note In Bulk.",
	"partnerProvision.reportExport": "Export Partner Report",
	"partnerProvision.reportPartners": "View Report Partner List",
	"partnerProvision.generateReport": "Generate Partner Report",
	"partnerProvision.partnerCommissionStatus": "Change Partner Commission Status",
	"partnerProvision.deletePartnerCommission": "Remove Partner Commission",
	"partnerProvision.reportAddCustomer": "Create Customer Commission",
	"partnerProvision.deleteCustomerCommission": "Remove Customer Commission",
	"partnerProvision.updateCustomerCommission": "Update Customer Commission",
	"partnerProvision.updateCustomerCommissionPercentage": "Update Customer Commission Percentage",
	"partnerProvision.resetCustomerCommission": "Revert Customer Commission",
	"partnerProvision.resetCustomerCommissionStatus": "Revert Customer Commission Status",
	"partnerProvision.updatePartnerProvisionPercentage": "Update Partner Provision Percentage",
	"partnerProvision.resetCustomerCommissionPercentage": "Revert Customer Commission Percentage",
	"partnerProvision.exportPartnerReport": "Export Partner Report",
	"partnerProvision.customerCommissionStatusUpdate": "Customer Commission Status Update",
	"partnerProvision.updatePartnerCommissionStatus": "Partner Commission Status Update",

	"customerPhoenixLicense.canSetUnlimitedExpiryDate": "Set Unlimited Expiry Date",
	"customerPhoenixLicense.changeMasterPassword": "Change Master Password",
	"customerPhoenixLicense.companyIndex": "View All Company List",
	"customerPhoenixLicense.companyUpdate": "Update Company Status",
	"customerPhoenixLicense.modulesIndex": "View All Company Module List",
	"customerPhoenixLicense.modulesUpdate": "Update Company Module",
	"customerPhoenixLicense.subscriptionsIndex": "View All Company Subscription",
	"customerPhoenixLicense.subscriptionsShow": "View Company Subscription",
	"customerPhoenixLicense.subscriptionsStore": "Create Company Subscription",

	"invoices.list": "Invoice List",
	"invoices.getUploadFileTempCredential": "Get Credentials To Upload Invoice",
	"invoices.groups.list": "All Invoice Groups List",
	"invoices.groups.list-active-invoice-groups": "Active Invoice Groups List",
	"invoices.groups.list-archived-invoice-groups": "Archived Invoice Groups List",
	"invoices.create": "Create Invoice",
	"invoices.removeInvoice": "Remove Invoice",
	"invoice-groups.addRequest": "Request To Add Invoice Group",
	"inventory-group.listInventoryGroup": "Inventory Group List",
	"inventory-group.createInventoryGroup": "Create Inventory Group",
	"inventory-group.getInventoryGroup": "Get Inventory Group",
	"inventory-group.updateInventoryGroup": "Update Inventory Group",
	"inventory-group.deleteInventoryGroup": "Delete Inventory Group",
	"inventory-group.getSuggestion": "Get Inventory Suggestion",
	"invoices.create-and-send-email": "Create An Invoice And Send As An Email",
	"invoices.generate-shopify": "Generate Shopify Invoice",
	"invoices.groups.archive": "Archive Invoices Group",
	"invoices.groups.restore": "Restore Invoices Group",
	"invoices.update-status": "Mark Invoice As Complete",
	"invoices.change-status": "Change Status Of An Invoice",
	"invoices.generatePDF": "Generate PDF Of SevDesk Invoice",
	"invoices.customers.list": "Get Customer List Having Whose Invoice Document Is Created",
	"invoices.customers.documents.list": "Get Document List",
	"invoices.customers.documents.download": "Download Document",
	"inventory-group.changeInventoryGroupStatus": "Change Inventory Group Status",
	"invoices.create-invoice-and-send-email-mark-complete": "Create Invoice and Send Email",
	"invoices.invoice-number": "Invoice Number",
	"invoices.fetchInvoiceStatus": "Get Invoice Status",

	"sev-desk.inventories.sync": "SevDesk Inventory Sync",
	"sev-desk.inventories.list": "SevDesk Inventory List",

	"tax-rules.list-sev-desk-tax-rules": "Tax Rules List",

	"toolREA.reaList": "Show REA List",
	"list.inventories": "Inventory List",
	"inventories.changeDisplayInInvoiceStatus": "Change Display In Invoice Status",
	"invoice.turnover-statistics": "Show Turnover Statistics",
	"organizations.initial-sync-new-fields": "Initially Sync New Fields",

	"history-log.machine": "History Logs For Machine",
	"history-log.list-posbill-license-history": "History Logs For License",
	"history-log.list-organization-change-history": "History Logs For Payment Status",
	"history-log.list-posbill-machine-auto-update-history": "History Logs For Auto Update Status",
	"history-log.user-activities": "History Logs For User Activities",
	"history-log.list-resigo-machine-history": "History Logs For Resigo Machine",
	"history-log.list-resigo-license-history": "History Logs For Resigo License",
	"history-log.list-posbill-machine-history": "Posbill Machine History List",

	"tax-rules.list-tax-rules": "Tax Rules List",
	"tax-rules.countries": "Countries List For Tax Rules Creation",
	"tax-rules.store": "Create Tax Rule",
	"tax-rules.delete": "Delete Tax Rule",

	"posbill-backup.uploadStatistics": "Upload Statistics",
	"posbill-backup.customers": "Get Customers",
	"posbill-backup.customer.uploadStatistics": "Upload Statistics",
	"posbill-backup.customer.backups": "Get Backups",
	"posbill-backup.customer.backups.requestDownload": "Request Download Backups",
	"posbill-backup.listDownloadRequests": "Download Request List",
	"posbill-backup.backup-download": "Download Backup",

	"customerInvoices.listInvoices": "List Customer Invoices",
	"customerInvoices.downloadInvoices": "Download Customer Invoices",
	"customerInvoices.sendInvoice": "Send Customer Invoice",

	"sev-desk.tax-rules.list": "SevDesk Tax Rules List",
	'mo': 'Mo',
	'di': 'Tu',
	'mi': 'We',
	'do': 'Th',
	'fr': 'Fr',
	'sa': 'Sa',
	'so': 'Su',
	'handler_update': "Handler Update"
}

export default en;
